import * as _ from 'lodash';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CONSTANTS } from '../constants';
import { ITranslationCorrectionFrequentWord } from '../main/translation/interfaces/ITranslationCorrection';
import {
    OkCancelDialogComponent,
    OkCancelDialogData,
} from '../shared/ok-cancel-dialog/ok-cancel-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
    ITranslationCorrection,
    TTranslationCorrectionWordType,
} from '../main/translation/interfaces/ITranslationCorrection';
import { ILanguages } from '../main/spyder/interfaces/ILanguages';
import { IDict } from 'app/main/translation/interfaces/IDict';

export const MAX_LENGTH_WORD = 60; // Can get quite long because of all the meta information

export class TranslationCorrectionUtils {
    /**
     * Initializes the form.
     *
     * @returns {FormGroup}
     */
    public static createToFixFormArray(
        suggestion: ITranslationCorrectionFrequentWord
    ): FormGroup[] {
        const words_dictCc = suggestion.dicts.filter(
            (dict) => dict.src === CONSTANTS.DICTIONARY_SRC.DICT_CC
        );
        const apiSrc: number[] = [
            CONSTANTS.DICTIONARY_SRC.MS_TRANSLATOR_API,
            CONSTANTS.DICTIONARY_SRC.GOOGLE_CLOUD_TRANSLATION_API,
        ];
        const dicts_TranslApi = suggestion.dicts.filter((dict) =>
            apiSrc.includes(dict.src)
        );
        const existingTranslations = suggestion.toWord.split(' / ');

        const toFixFormArray: FormGroup[] = [];

        // TODO should we move this up?
        if (suggestion.correction?.toFix) {
            suggestion.correction.toFix.forEach((tf) => {
                toFixFormArray.push(
                    this.formGroupForFixWord(
                        tf.text,
                        tf.pos,
                        tf.show,
                        tf.gen,
                        null,
                        tf.from,
                        tf.fromGen,
                        tf.subj,
                        tf.src
                    )
                );
            });
            return toFixFormArray;
        }

        // tn = normalized "to" word
        const addWordIfNotInArray = (
            word: string,
            pos?: string[],
            show?: boolean,
            gen?: string,
            tn?: string,
            from?: string,
            fromGen?: string,
            subj?: string[],
            src?: number
        ) => {
            if (
                !toFixFormArray.find(
                    (d) =>
                        d.get('text').value.toLowerCase() === word.toLowerCase()
                )
            ) {
                //  && !words_dictCc.find(d => d.tn.toLowerCase() === word)
                let wordToInsert = word;
                // Cut off word if it's too long
                if (wordToInsert.length > MAX_LENGTH_WORD) {
                    wordToInsert =
                        word.substring(0, MAX_LENGTH_WORD - 3) + '...';
                    var countStartPar = _.countBy(wordToInsert)['['] || 0;
                    var countEndPar = _.countBy(wordToInsert)[']'] || 0;
                    if (countStartPar > countEndPar) {
                        if (wordToInsert.endsWith('[...')) {
                            wordToInsert = wordToInsert.replace('[...', '...');
                        } else {
                            wordToInsert =
                                wordToInsert.substring(0, MAX_LENGTH_WORD - 4) +
                                '...]';
                        }
                    }
                }
                toFixFormArray.push(
                    TranslationCorrectionUtils.formGroupForFixWord(
                        wordToInsert,
                        pos,
                        show,
                        gen,
                        tn,
                        from,
                        fromGen,
                        subj,
                        src
                    )
                );
            }
        };

        // First, take the suggestions from dict.cc
        words_dictCc.forEach((transl, index) => {
            let show = index < 1;
            addWordIfNotInArray(
                transl.toLessGen,
                transl.pos,
                show,
                transl.toGen,
                transl.tn,
                transl.from,
                transl.fromGen,
                transl.subj,
                transl.src
            );
        });

        // Check if there is a suggestion from existing translation / MTITs
        existingTranslations.forEach((transl) => {
            addWordIfNotInArray(transl);
        });

        // Now, check if there is a suggestions from other dicts
        dicts_TranslApi.forEach((transl) => {
            addWordIfNotInArray(transl.to);
        });

        if (
            toFixFormArray.length > 0 &&
            toFixFormArray.filter((fg) => fg.value.show).length === 0
        ) {
            toFixFormArray[0].patchValue({ show: true });
        }

        return toFixFormArray;
    }

    static formGroupForFixWord(
        text: string,
        pos?: string[],
        show?: boolean,
        gen?: string,
        tn?: string,
        from?: string,
        fromGen?: string,
        subj?: string[],
        src?: number
    ): FormGroup {
        return new FormGroup({
            text: new FormControl(text, [
                Validators.required,
                Validators.maxLength(MAX_LENGTH_WORD),
            ]),
            pos: new FormControl(pos),
            show: new FormControl(show ? show : false),
            gen: new FormControl(gen),
            tn: new FormControl(tn || text), // normalized text
            from: new FormControl(from),
            fromGen: new FormControl(fromGen),
            subj: new FormControl(subj),
            src: new FormControl(src),
        });
    }

    static checkNumWordsTranslationStatusApprove(
        numWordsShown: number,
        matDialog: MatDialog
    ): boolean {
        if (numWordsShown > CONSTANTS.MAX_TRANSLATION_WORDS_SHOW_FRONTEND) {
            const message = `Only ${
                CONSTANTS.MAX_TRANSLATION_WORDS_SHOW_FRONTEND
            } translations can be selected to be displayed in the app. Please unselect ${
                numWordsShown - CONSTANTS.MAX_TRANSLATION_WORDS_SHOW_FRONTEND
            } of ${numWordsShown}.`;
            const confirmDialogRef = matDialog.open(OkCancelDialogComponent, {
                width: '275px',
                data: {
                    header: 'Too many translations',
                    message,
                    hideCancelButton: true,
                } as OkCancelDialogData,
                disableClose: false,
            });

            confirmDialogRef.afterClosed().subscribe((result) => {
                // this.logger.debug('The confirmation dialog was closed', result);
            });
            return false;
        } else if (numWordsShown === 0) {
            const message = `You need to select a min. of 1 to a max. of ${CONSTANTS.MAX_TRANSLATION_WORDS_SHOW_FRONTEND} translations that will be displayed in the app.`;
            const confirmDialogRef = matDialog.open(OkCancelDialogComponent, {
                width: '275px',
                data: {
                    header: 'No translation selected',
                    message,
                    hideCancelButton: true,
                } as OkCancelDialogData,
                disableClose: false,
            });

            confirmDialogRef.afterClosed().subscribe((result) => {
                // this.logger.debug('The confirmation dialog was closed', result);
            });
            return false;
        } else {
            return true;
        }
    }

    /**
     * Finds the best correction match for a word from given array of corrections.
     *
     * @param tcs array of ITranslationCorrection
     * @param word a word for witch to find a matching correction
     * @param pos part-of-speech: NOUN, VERB, ADJ, etc.
     * @param from optional base language (for example: 'de') that is used to convert words to lower case
     */
    public static findBestCorrectionMatch(
        tcs: ITranslationCorrection[],
        word: string,
        pos?: string,
        from?: ILanguages
    ): ITranslationCorrection {
        if (!tcs || tcs.length === 0) {
            return null;
        }
        if (word === null || word === undefined) {
            console.log(
                `findBestCorrectionMatch - word is null or undefind: ${word}`
            );
            return null;
        }
        if (word.length === 0) {
            console.log('findBestCorrectionMatch - word is an empty string');
            return null;
        }

        let fromWordLowerCase: string;

        // ### First, see if there is a case sensitive correction that matches the POS (NOUN, VERB, ADJ, etc.)
        let tc_found = tcs.find((tc) => {
            return (
                tc.case_sensitive &&
                tc.fromWord === word &&
                TranslationCorrectionUtils.isPOSMatch(tc.pos, pos)
            );
        });

        // ### Then, see if there is a case insensitive correction that matches the POS (NOUN, VERB, ADJ, etc.)
        if (!tc_found) {
            fromWordLowerCase = from
                ? word.toLocaleLowerCase(from)
                : word.toLowerCase();
            tc_found = tcs.find((tc) => {
                const tcLowerCase = from
                    ? tc.fromWord.toLocaleLowerCase(from)
                    : tc.fromWord.toLowerCase();
                return (
                    !tc.case_sensitive &&
                    tcLowerCase === fromWordLowerCase &&
                    TranslationCorrectionUtils.isPOSMatch(tc.pos, pos)
                );
            });
        }

        // ### Then, see if there is a case sensitive correction (no POS)
        if (!tc_found) {
            tc_found = tcs.find((tc) => {
                if (tc.pos) {
                    return false; // has a POS - don't take it
                }
                return tc.case_sensitive && tc.fromWord === word;
            });
        }

        // ### Finally, see if there is a case insensitive correction (no POS)
        if (!tc_found) {
            tc_found = tcs.find((tc) => {
                if (tc.pos) {
                    return false; // has a POS - don't take it
                }
                const tcLowerCase = from
                    ? tc.fromWord.toLocaleLowerCase(from)
                    : tc.fromWord.toLowerCase();
                return !tc.case_sensitive && tcLowerCase === fromWordLowerCase;
            });
        }
        return tc_found;
    }

    /**
     * Converts the toFix words to a single string (joining them with /)
     * @param tc the translation correction
     */
    public static translationCorrectionToString(
        tc: ITranslationCorrection
    ): string {
        if (tc.toFix) {
            let words = _(tc.toFix)
                .filter((tf) => tf.show)
                .take(4) // Limit to 4
                // .map((tf) => dictCc.removeMetaInformation(tf.text))
                .map((tf) => tf.text)
                .value(); 
            // let words = tc.toFix
            //     .filter((tf) => tf.show)
            //     // .map((tf) => dictCc.removeMetaInformation(tf.text))
            //     .map((tf) => tf.text);
            if (CONSTANTS.RTL_LANGUAGES.includes(tc.to)) {
                // console.log('translationCorrectionToString is RTL');
                words = words.reverse();
            }
            return words.join(' / ');
        } else {
            return tc.toWordFix;
        }
    }

    /**
     * Checks whether the two POS strings match.
     * Treats 'NOUN' and 'PROPN' interchangable (considers them equal).
     * If any of the two POS is null/undefined/empty then it won't be a match.
     * @param pos1 NOUN, VERB, ADJ, etc.
     * @param pos2 NOUN, VERB, ADJ, etc.
     */
    private static isPOSMatch(pos1?: string, pos2?: string): boolean {
        const nounPOS = ['NOUN', 'PROPN'];
        if (nounPOS.includes(pos1) && nounPOS.includes(pos2)) {
            return true;
        }
        return pos1 && pos1 === pos2;
    }

    /**
     * Extract word types like dial., coll., and austr. from dict entries (only takes DICTIONARY_SRC.DICT_CC currently).
     * @param dicts array of dictionary entries
     * @param threshold in percent (0.5 = 50%), defines on how many percent of the entries the type must be defined for it to be returned
     * @returns an array of word types
     */
    public static extractWordTypesFromDicts(
        dicts: IDict[],
        threshold = 0.5
    ): TTranslationCorrectionWordType[] {
        let typesNew: TTranslationCorrectionWordType[] = [];
        const words_dictCc = dicts.filter(
            (dict) => dict.src === CONSTANTS.DICTIONARY_SRC.DICT_CC
        );
        words_dictCc.forEach((dict) => {
            CONSTANTS.AVAILABLE_WORD_TYPES.forEach((type) => {
                const variants = [type.abbrev, ...(type.alt || [])];
                variants.forEach((variant) => {
                    if (
                        TranslationCorrectionUtils.isTypeFromDict(
                            dict.from,
                            variant
                        )
                    ) {
                        typesNew.push(type.abbrev);
                    }
                });
            });
        });
        // See https://stackoverflow.com/questions/48295094/count-number-of-times-a-string-is-repeated-in-array-using-lodash
        const typesNewCount: {
            name: TTranslationCorrectionWordType;
            count: number;
        }[] = _.values(_.groupBy(typesNew)).map((d) => ({
            name: d[0],
            count: d.length,
        }));

        const minOccurrance = words_dictCc.length * threshold;
        console.log(
            `typesNewCount ${JSON.stringify(
                typesNewCount
            )}, minOccurrance ${minOccurrance}`
        );
        typesNew = typesNewCount
            .filter((t) => t.count >= minOccurrance)
            .map((t) => t.name);
        return typesNew;
    }

    /**
     * Checks whether a certain type is contained in the word within brackets.
     * @param word for example `Jänner {m} <Jän.> [österr., seltener südd., schweiz.]`, `Kraut {n} [ugs.] [Tabak]`, or `Kraut {n} [bes. südd. und österr. für: Weißkohl]`
     * @param type for eample `ugs.`, `dial.`, or `österr.`
     * @returns true if the type matches
     */
    public static isTypeFromDict(word: string, type: string): boolean {
        const typeEscaped = type.replace('.', '\\.');
        // This is not a 100% correct search, but it should be sufficient for our use case:
        const regex = new RegExp(`\\[.*${typeEscaped}.*\\]`);
        return regex.test(word);
    }
}
