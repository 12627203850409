export const locale = {
    lang: 'en',
    data: {
        'lang_en': 'English',
        'lang_de': 'German',
        'lang_ar': 'Arabic',
        'lang_tr': 'Turkish',
        'lang_hi': 'Hindi',
        'lang_es': 'Spanish',
        'lang_zh': 'Chinese',
        'lang_pt': 'Portuguese',
        'lang_ru': 'Russian',
        'lang_hr': 'Croatian (beta)',
        'lang_fr': 'French (beta)',
        'lang_it': 'Italian',
        'lang_ro': 'Romanian (beta)',
        'lang_fa': 'Dari/Farsi (beta)',
        'lang_so': 'Somali (beta)',
        'lang_bs': 'Bosnian (beta)',
        'lang_sr': 'Serbian (beta)',
        'MTIT': {
            "suggest_other_translation": "Suggest other translation",
            'existing_rule': 'Existing rule',
            'existing_rule_text': 'There is an existing rule that will be overwritten! Please consider before submitting:',
            "suggest_new_translation": "Suggest other translation",
            'original_word': 'Original',
            'translated_word': 'Translated',
            'pos': 'Part of speech',
            'lemma': 'Lemma',
            'named_entity': 'Named entity',
            'case_sensitive': 'Case sensitive',
            'comment': 'Comment',
            "context": "Context",
            "submit": "Submit"
        }
    }
};
