import { ILanguages } from './main/spyder/interfaces/ILanguages';
import { TTranslationCorrectionWordType } from './main/translation/interfaces/ITranslationCorrection';

export const CONSTANTS = {
    WEBSOURCES_FOR_REVIEW: ['orftvthek'],

    AVAILABLE_TRANSLATION_LANGUAGES_FROM: [
        { code: 'de', name: 'German' },
        { code: 'en', name: 'English' }
    ],
    AVAILABLE_TRANSLATION_LANGUAGES: {
        de: [
            { code: 'ar', name: 'Arabic' },
            { code: 'bs', name: 'Bosnian (beta)' },
            { code: 'hr', name: 'Croatian (beta)' },
            { code: 'fa', name: 'Dari/Farsi (beta)' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'hu', name: 'Hungarian (beta)' },
            { code: 'it', name: 'Italian' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ro', name: 'Romanian' },
            { code: 'ru', name: 'Russian' },
            { code: 'sr', name: 'Serbian (beta)' },
            { code: 'sk', name: 'Slovak (beta)' },
            { code: 'so', name: 'Somali (beta)' },
            { code: 'es', name: 'Spanish' },
            { code: 'uk', name: 'Ukrainian (beta)' },
            { code: 'tr', name: 'Turkish (beta)' },
        ],
        en: [
            { code: 'ar', name: 'Arabic' },
            { code: 'bs', name: 'Bosnian (beta)' },
            { code: 'hr', name: 'Croatian (beta)' },
            { code: 'fa', name: 'Dari/Farsi (beta)' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'hu', name: 'Hungarian (beta)' },
            { code: 'it', name: 'Italian' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ro', name: 'Romanian' },
            { code: 'ru', name: 'Russian' },
            { code: 'sr', name: 'Serbian (beta)' },
            { code: 'sk', name: 'Slovak (beta)' },
            { code: 'so', name: 'Somali (beta)' },
            { code: 'es', name: 'Spanish' },
            { code: 'uk', name: 'Ukrainian (beta)' },
            { code: 'tr', name: 'Turkish (beta)' },
        ]
    },

    /**
     * Languages that are written right-to-left
     */
    RTL_LANGUAGES: ['ar', 'fa', 'he'] as ILanguages[],

    MAX_TRANSLATION_WORDS_SHOW_FRONTEND: 4, // How many words will be shown in the frontend

    DICTIONARY_SRC: {
        MS_TRANSLATOR_API: 1, // https://azure.microsoft.com/de-de/services/cognitive-services/translator/
        GOOGLE_CLOUD_TRANSLATION_API: 2, // https://cloud.google.com/translate/docs/basic/translating-text
        YANDEX_DICTIONARY_API: 3, // https://yandex.com/dev/dictionary/
        DICT_CC: 4, // https://www1.dict.cc/translation_file_request.php
    },

    AVAILABLE_POS: [
        { key: 'ADJ', description: 'adjective' },
        { key: 'ADP', description: 'adposition' },
        { key: 'ADV', description: 'adverb' },
        { key: 'AUX', description: 'auxiliary verb' },
        { key: 'CONJ', description: 'coordinating conjunction' },
        { key: 'CCONJ', description: 'coordinating conjunction' },
        { key: 'DET', description: 'determiner' },
        { key: 'INTJ', description: 'interjection' },
        { key: 'NOUN', description: 'noun' },
        { key: 'NUM', description: 'numeral' },
        { key: 'PART', description: 'particle' },
        { key: 'PRON', description: 'pronoun' },
        { key: 'PROPN', description: 'proper noun' },
        { key: 'PUNCT', description: 'punctuation' },
        { key: 'SCONJ', description: 'subordinating conjunction' },
        { key: 'SYM', description: 'symbol' },
        { key: 'VERB', description: 'verb' },
        { key: 'X', description: 'other' },
    ] as { key: string; description: string }[],

    AVAILABLE_DICT_SUBJECTS: [
        { abbrev: 'acad.', description: 'Academic Disciplines / Wissenschaft' },
        { abbrev: 'acc.', description: 'Accounting / Buchführung' },
        {
            abbrev: 'admin.',
            description: '(Public) Administration / (Öffentliche)\n Verwaltung',
        },
        {
            abbrev: 'agr.',
            description:
                'Agriculture, Aquaculture / Agrarwirtschaft, Land-\n und Gewässerbewirtschaftung',
        },
        { abbrev: 'anat.', description: 'Human Anatomy / Humananatomie' },
        { abbrev: 'archaeo.', description: 'Archaeology / Archäologie' },
        { abbrev: 'archi.', description: 'Architecture / Architektur' },
        {
            abbrev: 'armour',
            description:
                'Historic Armour / Rüstungen, historische\n Schutzbekleidung',
        },
        { abbrev: 'art', description: 'Art / Kunst' },
        { abbrev: 'astron.', description: 'Astronomy / Astronomie' },
        {
            abbrev: 'astronau',
            description: 'Astronautics / Astronautik, Raumfahrt',
        },
        { abbrev: 'audio', description: 'Audiology / Audiologie, Akustik' },
        {
            abbrev: 'automot.',
            description:
                'Automotive Engineering / Automobil- und\n Fahrzeugtechnik',
        },
        { abbrev: 'aviat.', description: 'Aviation / Luftfahrt, Flugwesen' },
        { abbrev: 'bibl.', description: 'Biblical / Biblisch' },
        { abbrev: 'bike', description: 'Bicycle / Fahrrad' },
        { abbrev: 'biochem.', description: 'Biochemistry / Biochemie' },
        { abbrev: 'biol.', description: 'Biology / Biologie' },
        { abbrev: 'biotech.', description: 'Biotechnology / Biotechnologie' },
        { abbrev: 'bot.', description: 'Botany, Plants / Botanik, Pflanzen' },
        { abbrev: 'brew', description: 'Brewing / Brauwesen' },
        { abbrev: 'chem.', description: 'Chemistry / Chemie' },
        {
            abbrev: 'climbing',
            description: 'Climbing, Mountaineering / Bergsteigerei\n ',
        },
        {
            abbrev: 'cloth.',
            description: 'Clothing, Fashion / Bekleidung, Mode',
        },
        {
            abbrev: 'comics',
            description:
                'Comics and Animated Cartoons / Comics und\n Zeichentrickfilme',
        },
        { abbrev: 'comm.', description: 'Commerce / Handel' },
        { abbrev: 'comp.', description: 'Computer Sciences / Informatik, IT' },
        { abbrev: 'constr.', description: 'Construction / Bauwesen' },
        {
            abbrev: 'cosmet.',
            description: 'Cosmetics & Body Care / Kosmetik und\n Körperpflege',
        },
        { abbrev: 'curr.', description: 'Currencies / Währungen' },
        { abbrev: 'dance', description: 'Dance / Tanz' },
        { abbrev: 'dent.', description: 'Dental Medicine / Zahnmedizin' },
        { abbrev: 'drugs', description: 'Drugs / Drogen' },
        {
            abbrev: 'ecol.',
            description: 'Ecology, Environment / Ökologie, Umwelt',
        },
        { abbrev: 'econ.', description: 'Economy / Wirtschaft, Ökonomie' },
        { abbrev: 'educ.', description: 'Education / Ausbildung' },
        {
            abbrev: 'electr.',
            description:
                'Electrical Engin., Electronics / Elektrotechnik,\n Elektronik',
        },
        {
            abbrev: 'engin.',
            description: 'Engineering / Ingenieurwissenschaften',
        },
        {
            abbrev: 'entom.',
            description: 'Entomology / Entomologie, Insektenkunde',
        },
        {
            abbrev: 'equest.',
            description: 'Equestrianism, Horses / Reitsport, Pferde\n ',
        },
        { abbrev: 'esot.', description: 'Esotericism / Esoterik' },
        { abbrev: 'ethn.', description: 'Ethnology / Ethnologie' },
        { abbrev: 'EU', description: 'European Union / Europäische Union' },
        {
            abbrev: 'F',
            description:
                'Fiction: Names and Titles in Literature, Film, TV, Arts\n / Fiktion: Namen und Titel in Literatur, Film, TV, Kunst',
        },
        { abbrev: 'film', description: 'Film / Film' },
        { abbrev: 'fin.', description: 'Finance / Finanzwesen' },
        {
            abbrev: 'FireResc',
            description: 'Firefighting & Rescue / Feuerwehr &\n Rettungsdienst',
        },
        {
            abbrev: 'fish',
            description:
                'Ichthyology, fish, fishing / Fischkunde, Fischen,\n Angelsport',
        },
        {
            abbrev: 'FoodInd.',
            description: 'Foodstuffs Industry / Lebensmittelindustrie\n ',
        },
        {
            abbrev: 'for.',
            description: 'Forestry / Forstwissenschaft, Forstwirtschaft\n ',
        },
        { abbrev: 'furn.', description: 'Furniture / Möbel' },
        { abbrev: 'games', description: 'Games / Spiele' },
        {
            abbrev: 'gastr.',
            description: 'Gastronomy, Cooking / Gastronomie, Kochen',
            top: true,
        },
        { abbrev: 'geogr.', description: 'Geography / Geografie', top: true },
        { abbrev: 'geol.', description: 'Geology / Geologie' },
        { abbrev: 'herald.', description: 'Heraldry / Heraldik' },
        {
            abbrev: 'hist.',
            description: 'History / Historische Begriffe, Geschichte',
        },
        { abbrev: 'hort.', description: 'Horticulture / Gartenbau' },
        { abbrev: 'hunting', description: 'Hunting / Jagd' },
        {
            abbrev: 'hydro.',
            description:
                'Hydrology & Hydrogeology / Hydrologie &\n Hydrogeologie',
        },
        { abbrev: 'idiom', description: 'Idiom / Idiom, Redewendung' },
        { abbrev: 'ind.', description: 'Industry / Industrie' },
        { abbrev: 'insur.', description: 'Insurance / Versicherungswesen' },
        { abbrev: 'Internet', description: 'Internet / Internet' },
        {
            abbrev: 'jobs',
            description: 'Jobs, Employment Market / Berufe, Arbeitsmarkt\n ',
        },
        { abbrev: 'journ.', description: 'Journalism / Journalismus' },
        { abbrev: 'law', description: 'Law / Jura, Rechtswesen' },
        {
            abbrev: 'libr.',
            description: 'Library Science / Bibliothekswissenschaft',
        },
        {
            abbrev: 'ling.',
            description: 'Linguistics / Linguistik, Sprachwissenschaft\n ',
        },
        { abbrev: 'lit.', description: 'Literature / Literatur' },
        {
            abbrev: 'market.',
            description:
                'Marketing, Advertising / Marketing, Werbung,\n Vertrieb und Handelswesen',
        },
        {
            abbrev: 'material',
            description:
                'Materials Science / Materialwissenschaft,\n Werkstoffkunde',
        },
        { abbrev: 'math.', description: 'Mathematics / Mathematik' },
        { abbrev: 'med.', description: 'Medicine / Medizin', top: true },
        {
            abbrev: 'MedTech.',
            description: 'Medical Engineering & Imaging /\n Medizintechnik',
        },
        { abbrev: 'meteo.', description: 'Meteorology / Meteorologie' },
        { abbrev: 'mil.', description: 'Military / Militärwesen' },
        { abbrev: 'mineral.', description: 'Mineralogy / Mineralogie' },
        {
            abbrev: 'mining',
            description: 'Mining & Drilling / Bergbau & Bohrtechnik',
        },
        { abbrev: 'mus.', description: 'Music / Musik' },
        { abbrev: 'mycol.', description: 'Mycology / Mykologie, Pilze' },
        { abbrev: 'myth.', description: 'Mythology / Mythologie' },
        {
            abbrev: 'name',
            description: 'Names of Persons / Namenkunde (nur\n Personennamen)',
            top: true,
        },
        {
            abbrev: 'naut.',
            description: 'Nautical Science / Nautik, Schifffahrtskunde\n ',
        },
        {
            abbrev: 'neol.',
            description: 'Neologisms / Neologismen (Wortneubildungen)\n ',
        },
        {
            abbrev: 'nucl.',
            description: 'Nuclear Engineering / Nukleartechnik',
        },
        {
            abbrev: 'oenol.',
            description: 'Oenology / Önologie, Lehre vom Wein',
        },
        { abbrev: 'optics', description: 'Optics / Optik' },
        {
            abbrev: 'orn.',
            description: 'Ornithology / Ornithologie, Vogelkunde',
        },
        { abbrev: 'pharm.', description: 'Pharmacy / Pharmazie' },
        {
            abbrev: 'philat.',
            description: 'Philately / Philatelie, Briefmarkenkunde',
        },
        { abbrev: 'philos.', description: 'Philosophy / Philosophie' },
        { abbrev: 'phonet.', description: 'Phonetics / Phonetik' },
        { abbrev: 'photo.', description: 'Photography / Fotografie' },
        { abbrev: 'phys.', description: 'Physics / Physik' },
        { abbrev: 'pol.', description: 'Politics / Politik' },
        {
            abbrev: 'print',
            description:
                'Print, Typography, Layout / Druck, Typografie,\n Layout',
        },
        { abbrev: 'proverb', description: 'Proverb / Sprichwort' },
        { abbrev: 'psych.', description: 'Psychology / Psychologie' },
        { abbrev: 'publ.', description: 'Publishing / Verlagswesen' },
        {
            abbrev: 'QM',
            description: 'Quality Management / Qualitätsmanagement',
        },
        { abbrev: 'quote', description: 'Quotation / Zitat' },
        {
            abbrev: 'RadioTV',
            description: 'Radio and Television / Radio und Fernsehen\n ',
        },
        { abbrev: 'rail', description: 'Rail / Eisenbahn' },
        { abbrev: 'RealEst.', description: 'Real Estate / Immobilien' },
        { abbrev: 'relig.', description: 'Religion / Religion' },
        { abbrev: 'rhet.', description: 'Rhetoric / Rhetorik' },
        { abbrev: 'sociol.', description: 'Sociology / Soziologie' },
        {
            abbrev: 'spec.',
            description: 'Specialized Term / Fachsprachlicher Ausdruck\n ',
        },
        { abbrev: 'sports', description: 'Sports / Sport' },
        { abbrev: 'stat.', description: 'Statistics / Statistik' },
        { abbrev: 'stocks', description: 'Stock Exchange / Börsenwesen' },
        {
            abbrev: 'T',
            description:
                'Taxonomic terms for animals, plants and fungi (incl.\n varieties and breeds) / Taxonomische Bezeichnungen für Tiere, Pflanzen und Pilze (inkl.\n Zuchtformen und Rassen)',
        },
        { abbrev: 'tech.', description: 'Technology / Technik' },
        {
            abbrev: 'telecom.',
            description: 'Telecommunications / Telekommunikation',
        },
        {
            abbrev: 'textil.',
            description:
                'Textiles, Textile Industry / Textilien,\n Textilindustrie',
        },
        { abbrev: 'theatre', description: 'Theatre / Theater' },
        { abbrev: 'tools', description: 'Tools / Werkzeuge' },
        { abbrev: 'toys', description: 'Toys / Spielzeug' },
        { abbrev: 'traffic', description: 'Traffic / Verkehrswesen' },
        {
            abbrev: 'transp.',
            description:
                'Transportation (Land Transport) / Transportwesen\n (Landtransport)',
        },
        { abbrev: 'travel', description: 'Travel Industry / Touristik' },
        {
            abbrev: 'TrVocab.',
            description: 'Travellers vocabulary / Reise-Wortschatz\n ',
        },
        {
            abbrev: 'unit',
            description:
                'Units, Measures, Weights / Einheiten, Maße,\n Gewichte',
        },
        {
            abbrev: 'urban',
            description: 'Urban Planning / Urbanistik, Stadtplanung',
        },
        {
            abbrev: 'UWH',
            description: 'UNESCO World Heritage / UNESCO-Welterbe',
        },
        {
            abbrev: 'VetMed.',
            description: 'Veterinary Medicine / Veterinärmedizin',
        },
        { abbrev: 'watches', description: 'Watches, Clocks / Uhren' },
        { abbrev: 'water', description: 'Waterbody / Gewässer' },
        { abbrev: 'weapons', description: 'Weapons / Waffen' },
        {
            abbrev: 'zool.',
            description: 'Zoology, Animals / Zoologie, Tierkunde',
            top: true,
        },
    ] as { abbrev: string; description: string; top?: boolean }[],

    AVAILABLE_WORD_TYPES: [
        {
            abbrev: 'austr.',
            description: 'Austriacism / Austriazismus',
            alt: ['österr.'],
        },
        {
            abbrev: 'coll.',
            description: 'Colloquial / Umgangssprache',
            alt: ['ugs.'],
        },
        { abbrev: 'dial.', description: 'Dialect / Dialekt' },
    ] as {
        abbrev: TTranslationCorrectionWordType;
        description: string;
        alt?: [string];
    }[],
} as const;
