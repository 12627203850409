import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguagesService } from '../languages.service';
import { ILanguage } from '../interfaces/ILanguage';

@Component({
  selector: 'app-languages-management-tool',
  templateUrl: './languages-management-tool.component.html',
  styleUrls: ['./languages-management-tool.component.scss'],
  animations: fuseAnimations
})
export class LanguagesManagementToolComponent implements OnInit {
  dataSource = new MatTableDataSource();
  displayedColumns = ['language', 'status'];

  allLanguages: ILanguage = {};

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('table', { static: false }) table: any;

  constructor(private _router: Router, private _route: ActivatedRoute, private _languagesService: LanguagesService) {
    this.allLanguages =  this._route.snapshot.data.data;
    // @ts-ignore
    this.dataSource.data = this.allLanguages;
    this.rewriteName(this.allLanguages);
  }

  ngOnInit(): void {
  }

  openLanguageDetails(language): void {
    this._languagesService.pageState = {
        languages: this.allLanguages
    };

    this._router.navigateByUrl(`/languages/languages-management-tool/${language.code}`);
  }

  rewriteName(languages): void {
    for (const language of languages) {
      if (language.status === 'Beta') {
        language.name = `${language.name} (Beta)`;
      }
    }
  }
}
