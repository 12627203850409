export const locale = {
    lang: 'de',
    data: {
        'lang_en': 'Englisch',
        'lang_de': 'Deutsch',
        'lang_ar': 'Arabisch',
        'lang_tr': 'Türkisch',
        'lang_hi': 'Hindi',
        'lang_es': 'Spanisch',
        'lang_zh': 'Chinesisch',
        'lang_pt': 'Portugiesisch',
        'lang_ru': 'Russisch',
        'lang_hr': 'Kroatisch (beta)',
        'lang_fr': 'Französisch (beta)',
        'lang_it': 'Italienisch',
        'lang_ro': 'Rumänisch (beta)',
        'lang_fa': 'Dari/Farsi (beta)',
        'lang_so': 'Somali (beta)',
        'lang_bs': 'Bosnisch (beta)',
        'lang_sr': 'Serbisch (beta)',
        'MTIT': {
            "suggest_other_translation": "Andere Übersetzung vorschlagen",
            'existing_rule': 'Bestehende Regel',
            'existing_rule_text': 'Es gibt eine existrierende Regel, die überschrieben wird! Bitte vor dem Absenden überprüfen:',
            'suggest_new_translation': 'Neue Übersetzung vorschlagen',
            'original_word': 'Original',
            'translated_word': 'Übersetzt',
            'pos': 'Wortart',
            'lemma': 'Lemma',
            'named_entity': 'Benannte Entität',
            'case_sensitive': 'Groß- und Kleinschreibung beachten',
            'comment': 'Kommentar',
            "context": "Kontext",
            "submit": "Abschicken"
        }
    }
};
