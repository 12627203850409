<div class="page-layout simple carded fullwidth inner-scroll" fxLayout="column">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/translation/suggestions'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start">
                    <h1 [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        Translation Test Tool
                    </h1>
                </div>
            </div>
        </div>

        <div class="content-card mat-white-bg" fusePerfectScrollbar>
            <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-md="row">

                <form class="mat-white-bg pt-24 pl-24 pr-24" style="width:50%;" fxLayout="column" fxFlex
                    [formGroup]="trFG">

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="30">
                            <mat-select placeholder="From Language" formControlName="from" required>
                                <mat-option [value]="'de'">German</mat-option>
                                <mat-option [value]="'en'">English</mat-option>
                                <!-- <mat-option [value]="'en'">English</mat-option> -->
                            </mat-select>
                            <mat-error *ngIf="false">
                                From is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="30">
                            <mat-select placeholder="To Language" formControlName="to" required>
                                <mat-option *ngFor="let lang of availableToLanguages" [value]="lang.code">
                                    {{ lang.name }}
                                </mat-option>
                                <!-- <mat-option [value]="'de'">German</mat-option> -->
                            </mat-select>
                            <mat-error *ngIf="false">
                                To is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-form-field>
                        <textarea matInput placeholder="Enter text in original (From) language"
                            formControlName="oriText" required></textarea>
                        <mat-error *ngIf="trFG.controls['oriText'].hasError('required')">oriText is required.
                        </mat-error>
                    </mat-form-field>

                    <br>
                    <br>
                    <br>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="30">
                            <mat-select placeholder="process" formControlName="process" required>
                                <mat-option [value]="'msTranslator'">msTranslator</mat-option>
                                <mat-option [value]="'googleTranslator'">googleTranslator</mat-option>
                                <mat-option [value]="'yandexTranslator'">yandexTranslator</mat-option>
                            </mat-select>
                            <mat-error *ngIf="false">
                                process is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mt-20">
                        <mat-checkbox formControlName="preprocessText">preprocessText</mat-checkbox>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mt-20">
                        <mat-checkbox formControlName="preprocessWords">preprocessWords</mat-checkbox>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mt-20"
                        *ngIf="trFG.get('process').value === 'msTranslator' && (trFG.get('from').value === 'en' || trFG.get('to').value === 'en' )">
                        <mat-checkbox formControlName="processWithAlignment">processWithAlignment</mat-checkbox>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mt-20">
                        <mat-checkbox formControlName="processText">processText</mat-checkbox>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mt-20">
                        <mat-checkbox formControlName="postprocessText">postprocessText</mat-checkbox>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mt-20">
                        <mat-checkbox formControlName="postprocessWords">postprocessWords</mat-checkbox>
                    </div>

                    <button mat-button color="primary" (click)="translationTest()" [disabled]="!trFG.valid">
                        <mat-icon [class.green-icon]="trFG.valid" [class.pulsiraj]="processing">search</mat-icon> Test
                    </button>

                    <button mat-button color="warn" (click)="clear()">
                        <mat-icon [class.red-icon]="trFG.valid">close</mat-icon> Clear
                    </button>

                    <mat-error *ngIf="!!errMsg">
                        {{errMsg}}
                    </mat-error>

                </form>

                <div class="pt-24 pl-24 pr-24" style="width:600px;padding:0;">
                    <textarea id="apiResponse">{{apiResponse | json}}</textarea>
                </div>

            </div>

        </div>
    </div>
</div>