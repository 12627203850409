import { ILanguages } from 'app/main/spyder/interfaces/ILanguages';
import { ITranslationSubtitles } from 'app/main/spyder/interfaces/ITranslation';
import { IDict } from './IDict';
import { IUser } from '../../users-and-customers/interfaces/IUser';

export interface ITranslationCorrectionWord {
    text: string;
    pos: string[]; // pos from IDict - for example ["verb"]
    show: boolean;
    gen?: string; // gender
    subj?: string[]; // Subject, eg. "pol.", "gastr.", "aviat."
    from?: string; // form word (in case of dictionary entry)
    fromGen?: string; // gender of the from word
    src?: number; // source of the translation (1 = Microsoft Translate, 2 = Google Translate, etc. - see CONSTANTS.DICTIONARY_SRC)
}

export interface ITranslationCorrection {
    _id?: string;

    educator_id: string | IUser; // users._id  -- educator who suggested translation
    edu_cust_id?: string; // customers._id -- customer to which the educator belongs
    admin_id: string; // users._id   -- admin who approved translation

    video_id: string; // videos._id
    translation_id: string; // translations._id
    subtitles_index: number; // key of 'translations.subtitles' array to get 'translations.subtitles.fromText'
    toWords_index: number; // key of 'translations.subtitles[3].toWords' array to get word which needs to be corrected

    from: ILanguages;
    to: ILanguages;
    fromWord: string; // word in original language
    toWord: string; // API translation
    /**
     * @deprecated use toWordsFix instead
     */
    toWordFix?: string; // translated correction
    toFix?: ITranslationCorrectionWord[]; // translated correction
    case_sensitive: boolean;
    comment: string; // comment by the user submitting the correction
    feedback: string; // feedback by the user approving or rejecting the correction

    rule: string; // translation rule
    rule_file: string; // a file where translation rule is

    version: number; // translation version
    status: TTranslationCorrectionStatus; // only admin can change this field

    src?: ETranslationCorrectionSource; // USER: 1, FREQUENT_WORDS: 2 (Automatically created by the system) - see ETranslationCorrectionSource
    pos?: string; // POS - part of speech, for example "NOUN", "VERB", "ADJ"
    lem?: string; // Lemma, for example: Tische -> Tisch, wusste -> wissen
    lemn?: string; // Normalized lemma (lower case), for example: Tische -> tisch, Weiß -> weiß, wusste -> wissen
    fn?: string; // normalized from word (lower case)
    prio?: number; // priority (1 = highest priority)
    types?: TTranslationCorrectionWordType[]; // Type of the original word, see https://englishlessonsbrighton.co.uk/different-language-varieties/ and https://www.dailywritingtips.com/12-types-of-language/
    examples?: ITranslationCorrectionFrequentWordExample[];
    created_at?: Date;
    updated_at?: Date;
}

export interface ITranslationCorrectionFrequentWord extends ITranslationCorrection {
    count?: number;
    frequency?: number;
    dicts?: IDict[];
    dictsLemma?: IDict[];
    correction?: ITranslationCorrection; // Translation correction (highest version)
    correctionLemma?: ITranslationCorrection; // Translation correction for the lemma, if one exists and lemma is different from word
}
  
export interface ITranslationCorrectionFrequentWordExample {
    video_id?: string;
    subtitle_id?: string;
    websource_id: string;
    sentence: string;
    subtitles: ITranslationSubtitles[];
}

export type TTranslationCorrectionStatus = 'pending' | 'approved' | 'rejected' | 'on_hold' | '';

export type TTranslationCorrectionWordType = 'coll.' | 'dial.' | 'austr.';

export enum ETranslationCorrectionSource {
    USER = 1,
    FREQUENT_WORDS = 2, // Automatically created by the system
    USER_WITH_DICT = 3, // Created by a user with input from the dictionary databases
    USER_WITH_CORRECTION = 4, // Created by a user with input from a previous correction
}

