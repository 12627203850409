<div class="page-layout simple carded fullwidth inner-scroll" fxLayout="column">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/translation/glossary'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start">
                    <h1 >
                        Glossareintrag
                    </h1>
                </div>
            </div>

            <button mat-raised-button class="mat-white-bg mt-sm-0 mr-16" [disabled]="!glossaryForm.valid || submitting" form="glossaryForm">
                Hinzufügen
            </button>
        </div>

        <div class="content-card mat-white-bg" fusePerfectScrollbar>
            <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row">
                <!-- FIRST COLUMN: -->
                <form class="mat-white-bg pt-24 pl-24 pr-24 w-100-p"
                      (ngSubmit)="onSubmitGlossaryWordForm()"
                      id="glossaryForm"
                      fxLayout="column"
                      fxFlex="4 0 40%"
                      [formGroup]="glossaryForm">

                    <div *ngIf="glossary && glossary._id" class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100">
                            <input matInput #inputId [readonly]="true" formControlName="_id" placeholder="ID">
                        </mat-form-field>
                        <button  matTooltip="Copy to clipboard" mat-button color="primary" type="button" (click)="copyToClipBoard(inputId)">
                            <mat-icon  alt="Copy to clipboard" *ngIf="!copied">file_copy</mat-icon>
                            <mat-icon style="color: green;" *ngIf="copied">check_circle</mat-icon> <!-- Icono de check_circle si se ha copiado -->
                        </button>
                    </div>

                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100" >
                            <textarea matInput formControlName="word" placeholder="Wort *"></textarea>
                            <mat-error *ngIf="formErrors.word && formErrors.word.required">
                                Wort ist gefragt!
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100" >
                            <textarea matInput formControlName="definition" placeholder="Definition *"></textarea>
                            <mat-error *ngIf="formErrors.definition && formErrors.definition.required">
                                Definition ist gefragt!
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-radio-group formControlName="type">
                            <mat-radio-button value="Nominativ">Nominativ</mat-radio-button>
                            <mat-radio-button value="Verben" class="margin-left">Verben</mat-radio-button>
                            <mat-radio-button value="Adjektiv" class="margin-left">Adjektiv</mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="formErrors.type && formErrors.type.required">
                            Wortart ist gefragt!
                        </mat-error>
                    </div>


                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Nominativ'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="nominativ_singular" placeholder="Nominativ Singular*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Nominativ'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="nominativ_plural" placeholder="Nominativ Plural*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Verben'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="verben_present_person_ich" placeholder="Präsens ich*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Verben'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="verben_present_person_du" placeholder="Präsens du*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Verben'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="verben_present_person_er_sie_es" placeholder="Präsens er, sie, es*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Verben'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="verben_present_person_wir" placeholder="Präsens wir*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Verben'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="verben_present_person_ihr" placeholder="Präsens ihr*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Verben'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="verben_present_person_sie" placeholder="Präsens sie*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Verben'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="verben_imperativ_person_singular" placeholder="Imperativ Singular*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Verben'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="verben_imperativ_person_plural" placeholder="Imperativ Plural*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Verben'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="verben_perfekt_partizip_2" placeholder="Perfekt Partizip II*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Verben'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="verben_hilfsverb" placeholder="Hilfsverb*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Adjektiv'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="adjektkiv_positiv" placeholder="Adjektiv positiv*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Adjektiv'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="adjektiv_komparativ" placeholder="Adjektiv komparativ*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Adjektiv'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="adjektiv_superlativ" placeholder="Adjektiv superlativ*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Nominativ'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="genitiv_singular" placeholder="Genitiv Singular*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Nominativ'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="genitiv_plural" placeholder="Genitiv Plural*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Nominativ'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="dativ_singular" placeholder="Dativ Singular*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Nominativ'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="dativ_plural" placeholder="Dativ Plural*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Nominativ'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="akkusativ_singular" placeholder="Akkusativ Singular*"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="glossaryForm.controls.type.value === 'Nominativ'">
                        <mat-form-field fxFlex="100">
                            <textarea matInput formControlName="akkusativ_plural" placeholder="Akkusativ Plural*"></textarea>
                        </mat-form-field>
                    </div>


                    <div *ngIf="!isProduction">
                        <h4>Debug Info</h4>
                        <p style="color: #BBBBBB;">Form is valid: {{glossaryForm.valid}}</p>

                        <p style="color: #BBBBBB;">formValidationErrors:</p>
                        <pre style="font-size: 0.8em;"><code>{{formValidationErrors | json}}</code></pre>

                        <p style="color: #BBBBBB;">formErrors:</p>
                        <pre style="font-size: 0.8em;"><code>{{formErrors | json}}</code></pre>

                        <p style="color: #BBBBBB;">Form value:</p>
                        <pre style="font-size: 0.8em;"><code>{{glossaryForm.value | json}}</code></pre>
                    </div>

                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100" >
                            <textarea matInput formControlName="exampleSentence1" placeholder="Beispielsätze 1"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100" >
                            <textarea matInput formControlName="exampleSentence2" placeholder="Beispielsätze 2"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100" >
                            <textarea matInput formControlName="exampleSentence3" placeholder="Beispielsätze 3"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100" >
                            <textarea matInput formControlName="exampleSentence4" placeholder="Beispielsätze 4"></textarea>
                        </mat-form-field>
                    </div>

                    <mat-form-field fxFlex="100">
                        <input matInput formControlName="source_url_1" placeholder="URL Quelle 1">
                        <mat-error *ngIf="formErrors.source_url_1">
                            Quelle 1 ist keine gültige URL.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="100">
                        <input matInput formControlName="source_url_2" placeholder="URL Quelle 2">
                        <mat-error *ngIf="formErrors.source_url_2">
                            Quelle 2 ist keine gültige URL.
                        </mat-error>
                    </mat-form-field>

                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100" >
                            <textarea matInput formControlName="source_text_1" placeholder="Quelle 3"></textarea>
                            <mat-error *ngIf="formErrors.source_text_1 && formErrors.source_text_1.pattern">
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100" >
                            <textarea matInput formControlName="source_text_2" placeholder="Quelle 4"></textarea>
                            <mat-error *ngIf="formErrors.source_text_2 && formErrors.source_text_2.pattern">
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100" >
                            <textarea matInput formControlName="source_text_3" placeholder="Quelle 5"></textarea>
                            <mat-error *ngIf="formErrors.source_text_3 && formErrors.source_text_3.pattern">
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <h4 fxFlex="1 0 auto">Linked word(s)</h4>
                    <h2 class="ion-no-margin ion-no-padding ion-custom-margin"> Weitere Glossareinträge für Wörter, die in diesem Zusammenhang auch relevant sind</h2>
                    <p class="ion-no-margin ion-no-padding">Bitte kopieren Sie die ID von Glossareinträgen, die bei diesem Eintrag verlinkt werden sollen.</p>
                    <div fxFlex="1 0 auto" formArrayName="linkedWords" cdkDropList class="word-order-list">
                        <div fxLayout="column" [formGroupName]="i" *ngFor="let id of linkedWords.controls; index as i"
                             class="word-order-box" >
<!--                             class="word-order-box" cdkDrag [class.word-order-box-show]="id.controls.show.value">-->
                            <div class="word-order-box-row1" fxLayout="row" fxLayoutAlign="start center">

                                <mat-form-field fxFlex="4 0 65px" class="word-drag-input" floatLabel="never">
                                    <input matInput placeholder="ID von Wörter" formControlName="text">
                                    <mat-error *ngIf="id.controls.text.dirty && id.controls.text.errors?.required">
                                        Text is required
                                    </mat-error>
                                    <mat-error *ngIf="id.controls.text.errors?.maxlength as err">
                                        Text is too long (max. {{err.requiredLength}}, actual: {{err.actualLength}})
                                    </mat-error>
                                </mat-form-field>
                                <div fxFlex="0 0 35px" class="word-drag-delete">
                                    <button type="button" mat-icon-button aria-label="Remove" (click)="deleteLinkedWord(i)" fxFlex="1 0 30px" title="Remove">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="mt-8 mb-16">
                        <button mat-raised-button type="button" (click)="addLinkedWord()">Add ID</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>