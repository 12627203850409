import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ITranslationCorrection } from 'app/main/translation/interfaces/ITranslationCorrection';
import { ITranslationCorrectionFrequentWord, TTranslationCorrectionWordType } from '../../main/translation/interfaces/ITranslationCorrection';
import { INLPToken } from '../../main/spyder/interfaces/ISubtitle';

/**
 * Response of `POST /translationcorrections` endpoint
 */
export interface TranslationCorrectionResponse {
    data: ITranslationCorrection;
    msg: string;
    success: boolean;
}

/**
 * Response of `POST /translationcorrections/list` endpoint
 */
export interface TranslationCorrectionsResponse {
    success: boolean;
    count: number;
    data: ITranslationCorrection[];
}

/**
 * Service for submitting new translation suggestions (MTITs).
 */
@Injectable()
export class TranslationCorrectionsEducatorService implements Resolve<any> {
    routeParams: any;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Promise<any>}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        this.routeParams = route.params;
        // return this.getVideoSubtitles(this.routeParams.webSourceId, this.routeParams.videoId, this.routeParams.language);
        return Promise.resolve();
    }

    /**
     * Create and send translation suggestion
     *
     * @param body The body of the post request
     */
    sendTranslationCorrection(body: ITranslationCorrection): Promise<TranslationCorrectionResponse> {
        return this._httpClient.post(`${environment.serviceApiBaseUrl}/translationcorrections`, body).pipe(
            map(res => res as TranslationCorrectionResponse)
        ).toPromise<TranslationCorrectionResponse>();
    }

    /**
     * Lists one translation suggestions
     *
     * @param from The language the words were tranlslated from
     * @param to The language the words were translated to
     * @param status Filter suggestions by status
     * @param fromWord List of suggestions can be limited to a single word
     */
    getTranslationSuggestion(from: string, to: string, status = 'pending', fromWord = ''): Promise<TranslationCorrectionResponse> {
        const body = {
            fromWord,
            from,
            to,
            rule: 'override',
            rule_file: 'Postwords',
            status
        };

        return this._httpClient.post(`${environment.serviceApiBaseUrl}/translationcorrections/getone`, body).pipe(
            map(res => res as TranslationCorrectionResponse)
        ).toPromise();
    }


    /**
     * Returns dictionary entries and corrections for a given word (NLP token).
     * 
     * @param {ILanguages} from The language the words were tranlslated from
     * @param {ILanguages} to The language the words were translated to
     * @param {number} includeDict 1 = include results from dict.cc 
     * @param {number} hasCorrFreqWords: 1 = words must have corrections (MTITs), 0 = wurds must not have corrections, undefined = all words
     * @returns {Promise<any>}
     */
    getDictsForToken(from: string, to: string, token: INLPToken, toWord: string, includeDict = 1, hasCorrFreqWords = 0): Promise<{data: ITranslationCorrectionFrequentWord[]}> {
        let params = new HttpParams();
        if (includeDict) {
            params = params.set('includeDict', includeDict.toString())
        }
        if (hasCorrFreqWords != undefined && hasCorrFreqWords != null) {
            params = params.set('hasCorrFreqWords', hasCorrFreqWords.toString())
        }
        const body = {
            token,
            toWord
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.serviceApiBaseUrl}/translations/dicts-for-token/${from}/${to}`, body, { params: params })
                .subscribe(response => {
                    resolve(response as {data: ITranslationCorrection[]});
                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     * Get the word type (coll., dial., austr.) for a certain word from exising translation corrections.
     *
     * @param from The language the words were tranlslated from
     * @param to The language the words were translated to
     * @param fromWord the word
     * @param pos the POS tag, for example VERB, NOUN, ADJ, etc.
     */
    getTypes(from: string, to: string, fromWord: string, pos: string): Promise<{types: TTranslationCorrectionWordType[]}> {
        const body = {
            fromWord,
            from,
            to,
            pos
        };

        return this._httpClient.post(`${environment.serviceApiBaseUrl}/translationcorrections/get-types`, body).pipe(
            map(res => res as {types: TTranslationCorrectionWordType[]})
        ).toPromise();
    }

}
