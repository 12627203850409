import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SpyderDataService } from '../spyder-data.service';
import { IWebsource } from '../interfaces/IWebsource';
import { IVideo, EReviewStatus } from '../interfaces/IVideo';
import { ISubtitle } from '../interfaces/ISubtitle';
import { AuthService } from 'app/auth/auth.service';
import { forkJoin, of } from 'rxjs';
import { ISubject } from '../interfaces/ISubject';

/**
 * Response of POST /videos/create endpoint
 */
export class ICreateVideoResponse {
    msg: string;
    success: boolean;
    video: IVideo;
    subtitle: ISubtitle;
}

@Injectable()
export class VideoDetailsService implements Resolve<any> {
    routeParams: any;
    webSources: IWebsource[];
    subjects: ISubject[];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {SpyderDataService} _spyderDataService
     */
    constructor(private _httpClient: HttpClient,
                private _spyderDataService: SpyderDataService,
                private _authService: AuthService) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Promise<any>}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            forkJoin({
                webSources: this._httpClient.get(`${environment.serviceApiBaseUrl}/websources/full`),
                subjects: this._httpClient.get(`${environment.serviceApiBaseUrl}/subjects?sort=name.de`),
            }).subscribe((response: {webSources: IWebsource[], subjects: {data: ISubject[]; count: number; success: boolean;}}) => {
                    this.webSources = response.webSources;
                    this.subjects = response.subjects.data;
                    if (this._spyderDataService.videoDetails) {
                        resolve({ webSources: this.webSources, subjects: this.subjects, video: this._spyderDataService.videoDetails });
                        this._spyderDataService.videoDetails = null;
                        return;
                    }
                    if (this.routeParams.webSourceId === 'new') {
                        const initVideo: IVideo = {
                            // _id: 'newid', // In spyder3, ID can/should not be passed
                            websource_id: '',
                            title: '',
                            description: '',
                            duration: 0,
                            pageURL: '',
                            publishDate: '',
                            expiryDate: '',
                            groupId: '',
                            sequence: 0,
                            imageURL: '',
                            sources: [],
                            subtitleURL: '',
                            tags: [],
                            isAvailable: true,
                            translations: [],
                            disabledDate: '2099-01-01',
                            originalLang: 'de',
                            offset: []
                        };
                        resolve({ webSources: this.webSources, subjects: this.subjects, video: initVideo });
                        return;
                    }

                    if (route.url[0].path === 'crawled-videos') {
                        this.getCachedVideoDetails().then(video => {
                            resolve({ webSources: this.webSources, subjects: this.subjects, video: video });
                        }, error => {
                            reject(error.msg || error);
                        });
                    } else {
                        this.getSavedVideoDetails(this._authService.userRoleSimple, this.routeParams.videoId).then(video => {
                            resolve({ webSources: this.webSources, subjects: this.subjects, video: video });
                        }, error => {
                            reject(error.msg || error);
                        });
                    }

                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     * Gets video details for a cached video
     *
     * @returns {Promise<any>}
     */
    getCachedVideoDetails(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.spyderBaseUrl}/videos/cache/details/${this.routeParams.videoId}`)
                .subscribe((response: any) => {
                    if (response.success === false) {
                        reject(response);
                    } else {
                        resolve(response);
                    }
                }, error => {
                    console.log('Error getting video details:', error);
                    reject(error.error || error);
                });
        });
    }

    /**
     * Gets video details for a saved video
     *
     * @returns {Promise<any>}
     */
    getSavedVideoDetails(
        role: 'administrator' | 'educator' | 'student' = 'administrator',
        videoId: string
    ): Promise<IVideo> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.serviceApiBaseUrl}/videos/details4${role}/${videoId}`)
                .subscribe((response: any) => {
                    if (response.success === false) {
                        reject(response);
                    } else {
                        resolve(response);
                    }
                }, error => {
                    console.log('Error getting video details:', error);
                    reject(error.error || error);
                });
        });
    }

    /**
     * Saves a video to azure storage for the specified web source
     *
     * @param {IVideo} video The video to be saved
     * @returns {Promise<ICreateVideoResponse>}
     */
    saveVideo(video: IVideo): Promise<ICreateVideoResponse> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.serviceApiBaseUrl}/videos/create`, video)
                .subscribe(response => {
                    resolve(response as ICreateVideoResponse);
                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     * Updates a video in azure storage for the specified web source
     *
     * @param {IVideo} video The video to be updated
     * @returns {Promise<any>}
     */
    updateVideo(video: IVideo): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${environment.serviceApiBaseUrl}/videos/update/${video._id}`, video)
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     * Makes the given video for a web source available or unavailable in Azure Table storage
     *
     * @param {videoId} The id of the video video to be updated
     * @param {boolean} enabled True if video will be enabled, false otherwise
     * @returns {Promise<any>}
     */
    setVideoEnabled(videoId: string, enabled: boolean): Promise<any> {
        let params = new HttpParams();
        params = params.append('isEnabled', enabled.toString());
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${environment.serviceApiBaseUrl}/videos/enabledisable/${videoId}`, null, { params: params })
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     * Updates the video subtitles review status.
     *
     * @param {videoId} The id of the video video to be updated
     * @param {boolean} reviewStatus subtitles_approved or subtitles_rejected
     * @returns {Promise<any>}
     */
    setVideoReviewStatus(
        videoId: string,
        reviewStatus: EReviewStatus
    ): Promise<{
        success: boolean;
        code: string;
        msg: string;
        video_id: string;
        data: IVideo;
    }> {
        let params = new HttpParams();
        params = params.append('status', reviewStatus.toString());
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    `${environment.serviceApiBaseUrl}/videos/reviewstatus/${videoId}`,
                    null,
                    { params: params }
                )
                .subscribe(
                    (response: {
                        success: boolean;
                        code: string;
                        msg: string;
                        video_id: string;
                        data: IVideo;
                    }) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error.error || error);
                    }
                );
        });
    }

    /**
     * Sets given video to the video of the day for given date
     *
     * @param {IVideo} video The video
     * @param {string} date The date as ISOString
     * @returns {Promise<any>}
     */
    setVideoOfDay(video: IVideo, date: string): Promise<any> {
        const params = new HttpParams()
            .set('force', 'true')
            .set('backward', 'false');
        const body = {
            video_id: video._id,
            originalLang: video.originalLang,
            ofdayDate: date,
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.serviceApiBaseUrl}/videos/ofday`, body, { params: params }).subscribe(response => {
                resolve(response);
            }, error => {
                reject(error.error || error);
            });
        });
    }

    /**
     * Deletes a video by ID
     *
     * @param {string} id The ID of the video to be deleted
     * @returns {Promise<any>}
     */
    deleteVideo(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(`${environment.serviceApiBaseUrl}/videos/delete/${id}`).subscribe(response => {
                resolve(response);
            }, error => {
                reject(error.error || error);
            });
        });
    }

    /**
     * Deletes the subtitles + translations and reloads them from the subtitles file
     *
     * @param {IVideo} video The video object to be updated
     * @returns {Promise<any>}
     */
    fullUpdateVideo(video: IVideo): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${environment.serviceApiBaseUrl}/videos/fullupdate/${video._id}`, video).subscribe(response => {
                resolve(response);
            }, error => {
                reject(error.error || error);
            });
        });
    }
}
