<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/uugotit-logo.png">
            </div>

            <div class="title">{{'LOGIN.HEADER' | translate}}</div>

            <div class="loginError">{{loginError}}</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field>
                    <input matInput placeholder="Username" formControlName="username">
                    <mat-error *ngIf="loginForm.get('username').hasError('required')">
                        Username is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input matInput type="password" placeholder="Password" formControlName="password">
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                    fxLayoutAlign="space-between center">
                    <mat-checkbox [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}" class="remember-me"
                        aria-label="Remember Me">
                        Remember me
                    </mat-checkbox>

                    <a class="forgot-password">
                        Forgot password?
                    </a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                    [disabled]="loginForm.invalid"
                    (click)="checkLogin(loginForm.value.username, loginForm.value.password)">
                    LOGIN
                </button>

            </form>

            <!-- <hr> -->

            <!--<div class="separator">-->
            <!--<span class="text">OR</span>-->
            <!--</div>-->

            <!--<button mat-raised-button class="google">-->
            <!--Log in with Google-->
            <!--</button>-->

            <!--<button mat-raised-button class="facebook">-->
            <!--Log in with Facebook-->
            <!--</button>-->

            <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link">Create an account</a>
            </div> -->

        </div>

    </div>

</div>