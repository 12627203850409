<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="onCancelClick()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar>

        <div *ngIf="isLoading" class="p-24" style="text-align: center;"> 
            Loading ...
        </div>

        <form *ngIf="!isLoading" name="suggestionForm" [formGroup]="suggestionForm" class="event-form w-100-p" fxLayout="column" fxFlex>

            <div *ngIf="existingRule" class="message-box info mb-16">
                <h2 style="margin-top: 8px;">
                    {{ "MTIT.existing_rule" | translate }}
                </h2>
                <p>
                    {{ "MTIT.existing_rule_text" | translate }}
                </p>
                <p>
                    {{ getCorrectionShortText(existingRule) }}
                </p>
                <p *ngIf="translation && translation.subtitles[existingRule.subtitles_index]">
                    <span>{{ "MTIT.context" | translate }}:</span> "{{ translation.subtitles[existingRule.subtitles_index].fromText }}"
                </p>
            </div>


            <div *ngIf="!existingRule && suggestion?.correction" class="message-box info mb-16">
                <div>
                    <!-- {{ "MTIT.existing_rule_text" | translate }} -->
                    You are editing an existing correction that was approved by another user
                    (version {{suggestion.correction.version}} from {{suggestion.correction.updated_at | date:'short'}}).
                </div>
            </div>

            <span class="pb-12">
                <span class="mat-caption">{{ "MTIT.original_word" | translate }} ({{ ('lang_' + translation.options.from) | translate }}):</span>&nbsp;
                <strong>{{ data.fromTok?.text || data.fromWord }}</strong>
            </span>
            <span class="pb-12">
                <span class="mat-caption">{{ "MTIT.translated_word" | translate }} ({{ ('lang_' + translation.options.to) | translate }}):</span>&nbsp;
                <strong>{{ toWordShow(data.toWord) }}</strong>
            </span>
            <span class="pb-12" *ngIf="data.fromTok?.pos || data.fromTok?.lemma">
                <span class="mat-caption">{{ "MTIT.pos" | translate }} / {{ "MTIT.lemma" | translate }}:</span>&nbsp;
                <strong>{{ data.fromTok?.pos }} / {{data.fromTok?.lemma}}</strong>
            </span>
            <span class="pb-12" *ngIf="data.fromTok?.ne">
                <span class="mat-caption">{{ "MTIT.named_entity" | translate }}:</span>&nbsp;
                <strong>{{ data.fromTok?.ne }}</strong>
            </span>

            <div class="mb-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="100" floatLabel="always">
                    <mat-label>Word types</mat-label>
                    <mat-select formControlName="types" multiple placeholder="-">
                        <mat-option *ngFor="let s of _frequentWordsService.availableWordTypes" [value]="s.abbrev">
                            {{s.abbrev}} <span style="color: #999; font-size: 0.85em;">({{ s.description }})</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="pb-12 corrections-dicts-from-lemma" *ngIf="getDictionaryTranslations().length === 0">
                <span *ngIf="getCorrectionsLemma().length > 0">
                    <span class="mat-caption">
                        <mat-icon class="s-12" title="The translations of the lemma are shown here as an aid">info</mat-icon> Lemma
                        translations (approved):
                    </span>&nbsp;
                    <span *ngFor="let word of getCorrectionsLemma()" [class.translation-not-shown]="!word.show">
                        <br>{{ word.text }} 
                        <span *ngIf="word.subj?.length > 0" class="translation-not-shown-subject">
                            {{ word.subj.join(', ') }} 
                        </span>
                    </span>
                </span>
    
                <span *ngIf="getCorrectionsLemma().length === 0 && getDictionaryTranslationsForLemma().length > 0">
                    <span class="mat-caption">
                        <mat-icon class="s-12" title="The translations of the lemma are shown here as an aid">info</mat-icon> Lemma
                        translations:
                    </span>&nbsp;
                    <span *ngFor="let dict of getDictionaryTranslationsForLemma()"><br>{{ dict.toLessGen }}</span>
                </span>
            </div>
    
            <!-- <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ "MTIT.suggest_new_translation" | translate }}</mat-label>
                <input matInput
                        type="text"
                        name="suggestion"
                        formControlName="suggestion"
                        required>
            </mat-form-field> -->

            <!-- Uses https://material.angular.io/cdk/drag-drop/overview -->
            <div class="mb-8"><small>Max. 4; drag to re-order; text in square brackets won't be shown to users in subtitles</small></div>

            <div fxFlex="1 0 auto" formArrayName="toFix" cdkDropList (cdkDropListDropped)="drop($event)" class="word-order-list">
                <div fxLayout="column" [formGroupName]="i" *ngFor="let dict of toFix.controls; index as i"
                    class="word-order-box" cdkDrag [class.word-order-box-show]="dict.controls.show.value">
                    <div class="word-order-box-row1" fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="0 0 30px" class="word-drag-indicator" cdkDragHandle>
                            <mat-icon title="Drag to move up or down">drag_indicator</mat-icon>
                        </div>
                        <mat-form-field fxFlex="4 0 65px" class="word-drag-input" floatLabel="never">
                            <input matInput placeholder="Word to show" formControlName="text">
                        </mat-form-field>
                        <mat-form-field fxFlex="0 0 65px" floatLabel="never" class="ml-8">
                            <mat-label>Subjects</mat-label>
                            <mat-select formControlName="subj" multiple>
                                <mat-option *ngFor="let s of _frequentWordsService.availableSubjects; index as i;" [class.subject-select-top]="s.top"
                                    [class.subject-select-top-5]="s.top && !_frequentWordsService.availableSubjects[i+1].top" [value]="s.abbrev">
                                    {{s.abbrev}} <span class="subject-select-description">({{ s.description }})</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div fxFlex="0 0 35px" class="word-drag-show">
                            <mat-checkbox formControlName="show" class="ml-12" title="Show to users?"></mat-checkbox>
                        </div>
                        <div fxFlex="0 0 35px"class="word-drag-delete">
                            <button mat-icon-button aria-label="Remove" (click)="deleteFixWord(i)" fxFlex="1 0 30px" title="Remove">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <div fxFlex="1 0 45px" class="word-drag-gender">
                            <span *ngIf="dict.controls.gen.value">
                                <span class="word-drag-language" title="Translation language">{{suggestion.to.toUpperCase()}}</span>
                                <span title="Gender"> {{ "{" + dict.controls.gen.value + "}" }}</span>
                            </span>
                            <span *ngIf="dict.controls.from.value" title="Meaning in original language">
                                <span *ngIf="dict.controls.gen.value">
                                    <br />
                                </span>
                                <span class="word-drag-language">{{suggestion.from.toUpperCase()}}</span>
                                {{dict.controls.from.value}}
                            </span>
                        </div>
                    </div>
                    <div class="word-order-box-row2" *ngIf="dict.controls.subj.value && dict.controls.subj.value.length > 0">
                        Subject(s): <span class="word-subjects">{{dict.controls.subj.value.join(' / ')}}</span>
                    </div>
                </div>
            
            </div>

            <div class="mt-8 message-box warning" fxFlex="1 0 auto" *ngIf="getNumWordsShown() === 0">
                You need to select 1 to {{maxWordsShownFrontend}} translations that will be shown in the app.
            </div>

            <div class="mt-8 message-box warning" fxFlex="1 0 auto" *ngIf="getNumWordsShown() > maxWordsShownFrontend">
                Only {{maxWordsShownFrontend}} translations will be shown in the app.
                Please unselect {{getNumWordsShown() - maxWordsShownFrontend}}.
            </div>

            <div class="mt-8">
                <button mat-raised-button (click)="addFixWord()">Add translation</button>
            </div>
             

            <div class="mt-16" fxFlex="1 0 auto" fxLayout="column">

                <mat-slide-toggle name="caseSensitive" formControlName="caseSensitive" class="mr-24" aria-label="{{ 'case_sensitive' | translate }}">
                    {{ "MTIT.case_sensitive" | translate }}
                </mat-slide-toggle>

                <div class="pt-8 pb-8">
                    <small>
                        When enabled, the original word will only match words with the same case 
                        (e.g. "Wissen" will not match "wissen" or "WISSEN").
                    </small>
                </div>

            </div>

            <div fxFlex="1 0 auto" fxLayout="column" class="contexts-container" *ngIf="suggestion?.examples && suggestion?.examples?.length">
                <h4 fxFlex="1 0 auto">Example Contexts</h4>

                <div fxFlex="1 0 auto">
                    <div class="contexts-container-example" *ngFor="let example of suggestion.examples">
                        <span *ngFor="let subtitle of example.subtitles">
                            <span *ngFor="let tok of subtitle.fromTok">
                                <span [style.font-weight]="tokenMatchesSuggestions(tok) ? 'bold' : 'normal'">{{tok.t}}{{tok.w}}</span>
                            </span>{{' '}}
                        </span>
                    </div>
                </div>
            </div>

            <mat-form-field appearance="outline" class="w-100-p mt-16">
                <mat-label>{{ "MTIT.comment" | translate }}</mat-label>
                <textarea matInput
                            formControlName="comment"
                            mat-maxlength="200"
                            max-rows="4">
                </textarea>
            </mat-form-field>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <!-- <button *ngIf="action ==='edit'"
                mat-button color="primary"
                class="mr-8"
                (click)="matDialogRef.close(['delete',eventForm])"
                aria-label="Delete"
                matTooltip="Delete">
            DELETE
        </button>

        <button *ngIf="action !== 'edit'"
                mat-button color="primary"
                class="save-button"
                (click)="matDialogRef.close(eventForm)"
                [disabled]="eventForm.invalid"
                aria-label="ADD">
            ADD
        </button>

        <button *ngIf="action === 'edit'"
                mat-button color="primary"
                class="save-button"
                (click)="matDialogRef.close(['save', eventForm])"
                [disabled]="eventForm.invalid"
                aria-label="SAVE">
            SAVE
        </button> -->
        <button
                mat-button color="primary"
                class="save-button"
                (click)="onSubmitClick()"
                [disabled]="suggestionForm.invalid"
                aria-label="SUBMIT">
            {{ "MTIT.submit" | translate | uppercase }}
        </button>

    </div>
</div>
