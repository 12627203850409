// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';

// tslint:disable-next-line:no-duplicate-imports
// import {default as _rollupMoment} from 'moment';

// const moment = _rollupMoment || _moment;
const moment = _moment;

import * as _ from 'lodash';
import { FormArray, FormGroup, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';


import { environment } from '../../environments/environment';

export interface IFormError {
    control: string;
    error: string;
    value: any;
}

export class FormUtils {

    /**
     * Return `true` if the `production` flag is set in the Angular environment variables.
     */
    public static get isProduction(): boolean {
        return environment.production;
    }

    public static getFormValidationErrors(form: FormGroup, path?: string): IFormError[] {
        const result = [];
        Object.keys(form.controls).forEach(key => {
          const formProperty = form.get(key);
          if (formProperty instanceof FormGroup) {
            result.push(...FormUtils.getFormValidationErrors(formProperty))
          }
          if (formProperty instanceof FormArray) {
            formProperty.controls.forEach((control, idx) => {
                if (control instanceof FormGroup) {
                    result.push(...FormUtils.getFormValidationErrors(control, `${key}.${idx}.`))
                }
            });
          }
          const controlErrors: ValidationErrors = formProperty.errors;
          if (controlErrors) {
            Object.keys(controlErrors).forEach(keyError => {
              result.push({
                'control': (path || '') + key,
                'error': keyError,
                'value': controlErrors[keyError]
              });
            });
          }
        });
      
        return result;
    }

    public static populateFormErrors(
        formErrors: { [key: string]: any },
        form: FormGroup
    ): any {
        for (const field in formErrors) {
            if (!formErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            formErrors[field] = {};

            // Get the control
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
                formErrors[field] = control.errors;
            }
        }
        return formErrors;
    }

    /**
     * Checks whether the value of the field is a) an existing customer or b) the name of an existing customer.
     * @param customers list of customers
     * @param allowEmpty of true, the validator won't return an error if the value is null or an empty string
     * @returns `{notExistingCustomer: {value: control.value}}` or null
     */
     public static nonExistingCustomerValidator = (
        customers: { _id?: string; name: string }[],
        allowEmpty = false
    ): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: any } | null => {
            // console.log('nonExistingCustomerValidator', control.value);
            if (
                allowEmpty &&
                (control.value === '' || control.value === null)
            ) {
                return null;
            }
            let existingCustomer;
            if (typeof control.value === 'string') {
                existingCustomer = customers.find(
                    (c) => c.name === control.value
                );
                // if (existingCustomer) {
                //     control.setValue(existingCustomer, {emitEvent: false});
                // }
            } else if (control.value) {
                existingCustomer = customers.find(
                    (c) => c._id === control.value._id
                );
            }
            // console.log('nonExistingCustomerValidator', control.value, existingCustomer)
            return !existingCustomer
                ? { notExistingCustomer: { value: control.value } }
                : null;
        };
    };
}
