import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { ILanguages } from 'app/main/spyder/interfaces/ILanguages';
import { TTranslationCorrectionStatus, ITranslationCorrection, ITranslationCorrectionFrequentWord } from 'app/main/translation/interfaces/ITranslationCorrection';
import { ITablePageState } from 'app/main/users-and-customers/interfaces/ITablePageState';
import * as _ from 'lodash';
import { CONSTANTS } from 'app/constants';
import * as moment from 'moment';

export interface PageState extends ITablePageState {
    words: any[];
    lastAccess?: moment.Moment;
}

@Injectable()
export class FrequentWordsService {

    public readonly availablePOS = CONSTANTS.AVAILABLE_POS;
    public readonly availablePOSByKey = _.keyBy(this.availablePOS, 'key');
    public readonly availableSubjects = _.sortBy(CONSTANTS.AVAILABLE_DICT_SUBJECTS, ['top', subj => subj.abbrev.toLowerCase()]);
    public readonly availableWordTypes = CONSTANTS.AVAILABLE_WORD_TYPES;
    
    pageState: PageState;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient, private _authService: AuthService) {
    }

    /**
     * Lists the most frequent words
     * 
     * @param {ILanguages} from The language the words were tranlslated from
     * @param {ILanguages} to The language the words were translated to
     * @param {number} limit how many words should be returned
     * @param {number} numTranslations how many translations to look at? (newest ones)
     * @param {number} includeDict 1 = include results from dict.cc  
     * @param {number} hasCorrFreqWords: 1 = words must have corrections (MTITs), 0 = wurds must not have corrections, undefined = all words
     * @param {boolean} onlyLemmas return only lemma words
     * @returns {Promise<any>}
     */
    getTranslationFrequentWords(from: ILanguages, to: ILanguages, limit?: number, numTranslations?: number, includeDict?: number, hasCorrFreqWords = 0, onlyLemmas = false): Promise<{data: ITranslationCorrectionFrequentWord[]}> {
        let params = new HttpParams();
        if (numTranslations) {
            params = params.set('numTranslations', numTranslations.toString())
        }
        if (includeDict) {
            params = params.set('includeDict', includeDict.toString())
        }
        if (limit) {
            params = params.set('limit', limit.toString())
        }
        if (hasCorrFreqWords != undefined && hasCorrFreqWords != null) {
            params = params.set('hasCorrFreqWords', hasCorrFreqWords.toString())
        }
        if (onlyLemmas) {
            params = params.set('onlyLemmas', '1');
        }
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.serviceApiBaseUrl}/translation-frequentwords/list-most-frequent/${from}/${to}`, { params: params })
                .subscribe(response => {
                    resolve(response as {data: ITranslationCorrection[]});
                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     * Assign (allocate)  frequent words to users.
     * 
     * @param {ILanguages} from The language the words were tranlslated from
     * @param {ILanguages} to The language the words were translated to
     * @param {number} limit how many words should be returned
     * @param {number} numTranslations how many translations to look at? (newest ones)
     * @param {number} includeDict 1 = include results from dict.cc  
     * @param {number} hasCorrFreqWords: 1 = words must have corrections (MTITs), 0 = wurds must not have corrections, undefined = all words
     * @param {boolean} onlyLemmas return only lemma words
     * @returns {Promise<any>}
     */
     postAssignTranslationFrequentWords(
        from: ILanguages,
        to: ILanguages,
        numPerUser: number,
        assignUntil: Date,
        userIds: string[],
        numTranslations?: number,
        includeDict?: number,
        hasCorrFreqWords = 0,
        onlyLemmas = false
    ): Promise<{ success: boolean; msg: string }> {
        const body: any = {
            from,
            to,
            numPerUser,
            assignUntil,
            userIds,
            numTranslations,
            includeDict,
            hasCorrFreqWords,
            onlyLemmas,
        };
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(
                    `${environment.serviceApiBaseUrl}/translation-frequentwords/assign`,
                    body
                )
                .subscribe(
                    (response) => {
                        resolve(response as { success: boolean; msg: string });
                    },
                    (error) => {
                        reject(error.error || error);
                    }
                );
        });
    }

    /**
     * Lists the assigned frequent words
     * 
     * @param {ILanguages} from The language the words were tranlslated from
     * @param {ILanguages} to The language the words were translated to
     * @param {number} limit how many words should be returned
     * @param {select} select the fields to be returned
     * @returns {Promise<any>}
     */
     getAssignedFrequentWords(
        from: ILanguages,
        to: ILanguages,
        assignUser?: string,
        assignUserCust?: string,
        limit?: number,
        sort?: string,
        select?: string
    ): Promise<{ data: ITranslationCorrectionFrequentWord[]; count: number; success: boolean; }> {
        let params = new HttpParams();

        if (limit) {
            params = params.set('limit', limit.toString())
        }
        if (select) {
            params = params.set('select', select.toString())
        }
        const body: any = {};
        if (assignUser) {
            body.assignUser = assignUser;
        }
        if (assignUserCust) {
            body.assignUserCust = assignUserCust;
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.serviceApiBaseUrl}/translation-frequentwords/list-assigned`, body, { params: params })
                .subscribe(response => {
                    resolve(response as {data: ITranslationCorrection[]; count: number; success: boolean;});
                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     *  Delete assigned frequent words.
     * 
     * @param {assignUser} delete for user ID
     * @param {assignUserCust} delete for customer ID
     * @returns {Promise<any>}
     */
    deleteAssignedFrequentWords(assignUserCust?: string, assignUser?: string): Promise<{code: string; msg: string; count: number;}> {
        let params = new HttpParams();
        if (assignUser) {
            params.set('assignUser', assignUser);
        }
        if (assignUserCust) {
            params.set('assignUserCust', assignUserCust);
        }
        return new Promise((resolve, reject) => {
            this._httpClient.delete(`${environment.serviceApiBaseUrl}/translation-frequentwords/assigned`, { params: params })
                .subscribe(response => {
                    resolve(response as {code: string; msg: string; count: number;});
                }, error => {
                    reject(error.error || error);
                });
        });
    }
    
}
