import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import videojs from 'video.js';
import { NGXLogger } from 'ngx-logger';
import { transition } from '@angular/animations';

export interface VideoJsOptions {
    controls: boolean;
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    sources: {
        src: string;
        type: string;
    }[];
    poster?: string; // URL of the poster image
    nativeTextTracks?: boolean;
    controlBar?: VideoJsControlBarOptions;
}

export interface VideoJsTextTrack {
    kind: string; // 'subtitles'
    srclang: string; // 'de',
    label: string; // 'Deutsch',
    src: string; // VTT file URL,
    default: boolean;
}

export interface VideoJsControlBarOptions {
    fullscreenToggle: boolean;
    pictureInPictureToggle: boolean;
    children?: string[];
}

@Component({
    selector: 'app-vjs-player',
    template: `
        <video
            #target
            class="video-js vjs-default-skin vjs-big-play-centered"
            controls
            muted
            playsinline
            preload="none"
            crossorigin="anonymous"
        ></video>
    `,
    styleUrls: ['./vjs-player.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
    @ViewChild('target', { static: true }) target: ElementRef;
    // see options: https://github.com/videojs/video.js/blob/maintutorial-options.html
    @Input() options: VideoJsOptions;
    @Input() textTrack: VideoJsTextTrack;
    player: videojs.Player;
    textTrackElement: any;

    constructor(private elementRef: ElementRef, private _logger: NGXLogger) {}

    ngOnInit() {
        console.log('VjsPlayerComponent shared component loaded!');
        this.initVideo();
    }

    private initVideo() {
        const thisPage = this;
        const addOptions = {
            nativeTextTracks: true,
            persistTextTrackSettings: true,
        };
        // instantiate Video.js
        this.player = videojs(
            this.target.nativeElement,
            Object.assign(addOptions, this.options),
            function onPlayerReady() {
                thisPage._logger.debug('onPlayerReady', this);
                thisPage.initSubtitles();
                thisPage.loadVolumeAndMuted();

                this.on('volumechange', (event) => {
                    thisPage._logger.debug(
                        `player volume changed: ${this.volume()}, muted: ${this.muted()}`
                    );
                    thisPage.onPlayerVolumeChange();
                });
            }
        );
    }

    private initSubtitles() {
        if (this.textTrack) {
            if (this.textTrackElement) {
                this.player.removeRemoteTextTrack(this.textTrackElement);
            }
            this._logger.debug('Adding text track:', this.textTrack);
            this.textTrackElement = this.player.addRemoteTextTrack(
                this.textTrack,
                false
            );
        }
    }

    public setNewVideo(options: VideoJsOptions, textTrack: VideoJsTextTrack) {
        this.options = options;
        this.textTrack = textTrack;
        this._logger.debug(`setNewVideo ${options.poster}`);

        const muted = this.player.muted();
        this._logger.debug('muted', muted);
        // this.player.reset();
        this.player.src(options.sources);
        this.player.poster(options.poster);
        this.loadVolumeAndMuted();
        this.initSubtitles();
    }

    ngOnDestroy() {
        this._logger.debug('VjsPlayerComponent.ngOnDestroy()', this);
        // destroy player
        if (this.player) {
            this.player.dispose();
        }
    }

    onPlayerVolumeChange() {
        localStorage.setItem('video_player_volume', this.player.volume());
        localStorage.setItem(
            'video_player_muted',
            this.player.muted() ? '1' : '0'
        );
    }

    loadVolumeAndMuted() {
        let volume = localStorage.getItem('video_player_volume');
        if (volume === null) {
            volume = '1';
        }
        const muted = localStorage.getItem('video_player_muted') === '1';
        this._logger.debug('applyVolumeToPlayer', volume, muted);
        this.player.volume(Number.parseFloat(volume));
        this.player.muted(muted);
    }

    set currentTime(currentTime: number) {
        if (this.player) {
            this.player.currentTime(currentTime);
        }
    }

    get currentTime(): number {
        return this.player?.currentTime();
    }

    /**
     * Skips some seconds in the video
     *
     * @param seconds The seconds to be skipped
     * @param source The video source
     */
    skipVideo(seconds: number) {
        this._logger.debug(`Skipping video ${seconds} seconds`);
        if (this.player) {
            this.player.currentTime(this.player.currentTime() + seconds);
            this.player.userActive(true);
        }
    }

    /**
     * Sets current time to 50% of the video.
     */
    jumpToMiddle() {
        this._logger.debug('Jumping to middle of the video');
        if (this.player) {
            this.player.currentTime(this.player.duration() / 2);
            this.player.userActive(true);
        }
    }
}
