import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FuseNavigationService } from './../../@fuse/components/navigation/navigation.service';
import { environment } from '../../environments/environment';
import { IUser } from '../main/users-and-customers/interfaces/IUser';
import jwt_decode from 'jwt-decode';
import { NGXLogger } from 'ngx-logger';
import { ILicense } from 'app/main/users-and-customers/interfaces/ILicense';
import { IWebsource } from 'app/main/spyder/interfaces/IWebsource';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private userInfo: IUser;
    private licenseInfo: ILicense;
    private accessToken = '';

    constructor(private _router: Router,
                private _httpClient: HttpClient,
                private _fuseNavigationService: FuseNavigationService,
                private _logger: NGXLogger) {

    }

    /**
     * Get expiration date of the jwt Token by decoding it
     *
     * @returns {Date} The expiration date of jwt Token
     */
    getTokenExpirationDate(): Date {
        const decoded = jwt_decode(this.accessToken) as any;

        if (decoded.exp === undefined) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    /**
     * Checks if jwt Token is expired
     *
     * @returns {boolean} True if expired, otherwise false
     */
    isTokenExpired(): boolean {
        const date = this.getTokenExpirationDate();
        if (date === undefined) {
            return false;
        }
        return !(date.valueOf() > new Date().valueOf());
    }

    /**
     * Loads token from storage if we don't have it and is stored
     */
    checkStorageToken(): void {
        // If we don't have token
        if (this.accessToken === '') {
            const sessionToken = sessionStorage.getItem('token');
            const localToken = localStorage.getItem('token');
            if (localToken) {
                this.accessToken = localToken;
                this.userInfo = JSON.parse(localStorage.getItem('user'));
                this.licenseInfo = JSON.parse(localStorage.getItem('license'));
            } else if (sessionToken) {
                this.accessToken = sessionToken;
                this.userInfo = JSON.parse(sessionStorage.getItem('user'));
                this.licenseInfo = JSON.parse(sessionStorage.getItem('license'));
            }

            // If user logged in
            if (this.userInfo) {
                this.updateMenu();
            }
        }
    }

    /**
     * Updates FuseNavigation based on role of logged in user
     */
    updateMenu(): void {
        if (this.user.role !== 'administrator') {
            this._fuseNavigationService.removeNavigationItem('spyder');
            this._fuseNavigationService.removeNavigationItem('users-and-customers');
            this._fuseNavigationService.removeNavigationItem('trtest');
            this._fuseNavigationService.removeNavigationItem('languages-management-tool');
            // Main item "Translation":
            this._fuseNavigationService.removeNavigationItem('apply-corrections');
            this._fuseNavigationService.removeNavigationItem('assign-frequent-words');
        }
        if (!this.user.corrector) {
            this._fuseNavigationService.removeNavigationItem('translation');
        }
    }

    /**
     * Logs in user
     *
     * @param {string} username The username
     * @param {string} password The password
     * @param {boolean} rememberMe Keeps user logged in after browser closed if True
     *
     * @returns {Promise<any>}
     */
    login(username: string, password: string, rememberMe: boolean): Promise<any> {
        const body = {
            username: username,
            password: password
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.serviceApiBaseUrl}/users/login`, body)
                .subscribe((response: {jwtToken: string; license: ILicense; loggedUser: IUser; msg: string; success: boolean}) => {
                    this._logger.debug('User logged in: ', response);

                    this.userInfo = response.loggedUser;
                    this.licenseInfo = response.license;
                    this.accessToken = response.jwtToken;

                    // Save token in local storage or session storage
                    if (rememberMe) {
                        this.saveUserToStorage(localStorage, this.accessToken, this.user, this.license);
                        this.deleteUserFromStorage(sessionStorage);
                    } else {
                        this.saveUserToStorage(sessionStorage, this.accessToken, this.user, this.license);
                        this.deleteUserFromStorage(localStorage);
                    }

                    this.updateMenu();
                    resolve(response);
                }, error => {
                    this._logger.error('Error logging in: ', error);
                    reject(error.error || error);
                });
        });
    }

    /**
     * Logs user out
     */
    logout(): void {
        this.deleteUserFromStorage(localStorage);
        this.deleteUserFromStorage(sessionStorage);
        this.accessToken = '';
        this._router.navigate(['/login']);
    }

    /**
     * Checks if user is logged in
     *
     * @returns {boolean} True if user is logged in, false otherwise
     */
    isLoggedIn(): boolean {
        return this.accessToken > '' && !this.isTokenExpired();
    }

    /**
     * Redirect user to page depending on their role
     */
    redirectByRole(): void {
        if (!this.isLoggedIn()) {
            this.logout();
        } else {
            let redirect;

            switch (true) {
                case this.userInfo.corrector && this.userInfo.role !== 'administrator':
                    redirect = '/translation/suggestions';
                    break;
                case this.userInfo.role === 'cust_ideal':
                    redirect = '/translation/glossary';
                    break;
                default:
                    redirect = '/spyder/crawl-status';
                    break;
            }
            console.log('redirect ', redirect);
            this._router.navigateByUrl(redirect);
        }
    }

    get user(): IUser {
        return this.userInfo;
    }

    get token(): string {
        return this.accessToken;
    }

    get license(): ILicense {
        return this.licenseInfo;
    }

    get userRoleSimple(): 'administrator' | 'educator' | 'student' {
        switch (this.user?.role) {
            case 'administrator':
                return 'administrator'
            case 'educator':
            case 'cust_transl_supervisor':
            case 'cust_transl_corrector':
                return 'educator';
            case 'student':
                return 'student'
            default:
                return null;
        }

    }

    saveUserToStorage(storage: Storage, accessToken: string, user: IUser, license: ILicense) {
        storage.setItem('token', accessToken);
        storage.setItem('user', JSON.stringify(user));
        storage.setItem('license', JSON.stringify(license));
    }

    deleteUserFromStorage(storage: Storage) {
        storage.removeItem('token');
        storage.removeItem('user');
        storage.removeItem('license');
    }

    filterWebsourcesByUserLicense(websources: IWebsource[]): IWebsource[] {
        if (this.user && this.user.role === 'administrator') {
            return websources;
        }
        if (!this.license) {
            this._logger.debug('Filtering websources - got no license');
            return [];
        }
        this._logger.debug('Filtering websources:', this.license.websources);
        return websources.filter(ws => this.license.websources.includes(ws.id))
    }
}
