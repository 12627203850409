import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Resolve, ActivatedRouteSnapshot} from '@angular/router';
import { environment } from 'environments/environment';
import { ILanguage } from '../interfaces/ILanguage';

@Injectable()
export class LanguageManagementToolService implements Resolve<any> {

    /**
     * constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LanguageManagementToolService} _languageManagementToolService
     */
    constructor(private _httpClient: HttpClient) {
    }


    /**
     * Resolver
     *
     * @returns {Promise<any>}
     */
    // @ts-ignore
    resolve(route: ActivatedRouteSnapshot): Promise<any> {
        const code = route.params.code;

        return this.getLanguageById(code).then(language => {
            return Promise.resolve({
                language
            });
        }, error => {
            return Promise.reject(error.error || error);
        });
    }

    /**
     * Gets a language by code
     *
     * @param {string} code code of the customer
     * @returns {Promise<ILanguage>}
     */
    getLanguageById(code: string): Promise<ILanguage> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.serviceApiBaseUrl}/languages/${code}`)
                .subscribe((response: any) => {
                    resolve(response);
                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     * Updates a language by ID
     *
     * @param {ILanguage} language updated
     * @param {string} code The code of the language
     * @returns {Promise<any>}
     */
    updateLanguage(language: ILanguage, code: string): Promise<any> {
      return new Promise((resolve, reject) => {
        this._httpClient.put(`${environment.serviceApiBaseUrl}/languages/${code}`, language)
          .subscribe((response: any) => {
            resolve(response);
          }, error => {
            reject(error.error || error);
          });
      });
    }
}
