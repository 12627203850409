import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { PageState, SpyderDataService } from '../spyder-data.service';
import { IWebsource } from '../interfaces/IWebsource';
import { NGXLogger } from 'ngx-logger';
import {IVideoDuplicateSave} from '../interfaces/IVideoDuplicateSave';
import {  ICountDuplicatesResponse } from '../interfaces/ICountDuplicates';

@Injectable()
export class DuplicatedVideosService implements Resolve<any> {
    webSources: IWebsource[];
    onCachedVideosChanged: BehaviorSubject<any>;

    constructor(private _httpClient: HttpClient,
                private _spyderDataService: SpyderDataService,
                private _logger: NGXLogger) {
        // Set the defaults
        this.onCachedVideosChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Promise<any>}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.initialize()
            ]).then(
                () => {
                    resolve(null);
                },
                reject
            );
        });
    }

    /**
     * Gets web sources and then calls getVideoHelper
     *
     * @returns {Promise<any>}
     */
    initialize(): Promise<any> {
        return new Promise((resolve, reject) => {
            // If we have a state, we don't need to reload the WebSources
            const state: PageState = this._spyderDataService.cachedPageState;

            if (state) {
                this.onCachedVideosChanged.next({ state: state });
                this._spyderDataService.cachedPageState = null;
                resolve(state);
                return;
            }

            // If web sources have been loaded in another component, no need to call API again
            if (this._spyderDataService.webSources) {
                this.webSources = this._spyderDataService.webSources;
                this.getVideosHelper().then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            } else {
                this._httpClient.get(`${environment.serviceApiBaseUrl}/websources/full`)
                    .subscribe((response: any) => {
                        this._spyderDataService.webSources = this.webSources = response;
                        this.getVideosHelper().then(res => {
                            resolve(res);
                        }).catch(err => {
                            reject(err);
                        });
                    }, error => {
                        reject(error.error || error);
                    });
            }
        });
    }

    /**
     * Helper function to get videos for the first web source to be displayed
     *
     * @returns {Promise<any>}
     */
    getVideosHelper(): Promise<any> {
        return new Promise((resolve, reject) => {
            const webSource = this._spyderDataService.webSource ? this._spyderDataService.webSource : this.webSources[0];
            const params = new HttpParams()
                .set('limit', '100')
                .set('sort', '-publishDate -groupId sequence');
            const body = {
                subtitleURL: {
                    $ne: ''
                }
            };

            this.getVideos(webSource, params, body).then(res => {
                const initState: PageState = {
                    selectedWebSource: webSource,
                    webSources: this.webSources,
                    videos: res.data,
                    total: res.count,
                    pageIndex: 0,
                    pageSize: 100,
                    search: '',
                    scrollTop: 0,
                    selectedVideos: []
                };

                // If crawler running
                if (typeof res === 'string') {
                    this.onCachedVideosChanged.next({ message: 'Crawler is running, please wait till it finishes or stop the crawl.', state: initState });
                }
                else {
                    this.onCachedVideosChanged.next({ state: initState });
                }
                resolve(res);
            }, error => {
                reject(error.error || error);
            });
        });
    }

    /**
     * Gets all cached videos for a web source with the given list config
     *
     * @param {IWebsource} webSource The web source
     * @param params The call params
     * @param body The call body
     * @param onlyDuplicates
     * @returns {Promise<any>}
     */
    getVideos(webSource: IWebsource, params: HttpParams, body: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${environment.serviceApiBaseUrl}/videos/cache/list/${webSource.id}/duplicates`;
            this._httpClient.post(url, body, { params: params })
                .subscribe(response => {
                    this._logger.debug('Got videos: ', response);
                    resolve(response);
                }, error => {
                    this._logger.error('Error getting videos: ', error);
                    reject(error.error || error);
                });
        });
    }

    /**
     * Gets all cached videos for a web source with the given list config
     *
     * @returns {Promise<any>}
     */
    getDuplicatesCount(): Promise<ICountDuplicatesResponse>
    {
        return new Promise((resolve, reject) => {
            const url = `${environment.serviceApiBaseUrl}/videos/cache/duplicates/count`;
            this._httpClient.get(url)
                .subscribe(response => {
                    this._logger.debug('Got resp: ', response);
                    resolve(response as ICountDuplicatesResponse);
                }, error => {
                    this._logger.error('Error getting count of duplicates: ', error);
                    reject(error.error || error);
                });
        });
    }

    /**
     * Saves videos to azure database for a web source
     *
     * @param {string[]} videos Array containing videoIds of the videos
     * @returns {Promise<any>}
     */
    saveToDatabase(videos: string[]): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.serviceApiBaseUrl}/videos/save`, videos)
                .subscribe(response => {
                    console.log('Saved videos: ', response);
                    resolve(response);
                }, error => {
                    console.log('Error saving videos: ', error);
                    reject(error.error || error);
                });
        });
    }

    /**
     * Saves videos to azure database for a web source
     *
     * @param {string[]} videos Array containing videoIds of the videos with the ids of tasks to attach to the video
     * @returns {Promise<any>}
     */
    saveToDatabaseWithTaskReplacement(videos: IVideoDuplicateSave[]): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.serviceApiBaseUrl}/videos/save`, {videos, replacement: true})
                .subscribe(response => {
                    console.log('Saved videos: ', response);
                    resolve(response);
                }, error => {
                    console.log('Error saving videos: ', error);
                    reject(error.error || error);
                });
        });
    }

    /**
     * Takes subtitleURL and converts it into JSON and stores it into subtitles mongo collection
     *
     * @param {string[]} videos Array containing videoIds of the videos
     * @param {boolean} force If true delete existing video subtitle(s) and create new. Use it to replace existing subtitle. Default: false
     * @param {boolean} storeOrigFile To store original vtt or xml text into orig_file field. Default: false
     * @param {number} limitSubtitlesTo Limit number of subtitle phrases. Usually to create small subtitles for video translation tests.
     * @returns {Promise<any>}
     */
    storeSubtitles(videos: string[], force: boolean = false, storeOrigFile: boolean = false, limitSubtitlesTo?: number, returnSubtitles = false): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = new HttpParams()
                .set('force', force ? 'true' : 'false')
                .set('storeOrigFile', storeOrigFile ? 'true' : 'false')
                .set('returnSubtitles', returnSubtitles ? 'true' : 'false');
            if (limitSubtitlesTo) {
                params.set('limitSubtitlesTo', limitSubtitlesTo.toString());
            }
            this._httpClient.post(`${environment.serviceApiBaseUrl}/subtitles`, videos, { params: params })
                .subscribe(response => {
                    console.log('Stored subtitles: ', response);
                    resolve(response);
                }, error => {
                    console.log('Error storing subtitles: ', error);
                    reject(error.error || error);
                });
        });
    }
}
