import { MatSnackBar } from '@angular/material/snack-bar';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog,
} from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { MatColors } from '@fuse/mat-colors';
import {
    FormBuilder,
    FormGroup,
    FormControl,
    Validators,
    FormArray,
} from '@angular/forms';
import { ITranslation } from 'app/main/spyder/interfaces/ITranslation';
import { TranslationCorrectionsEducatorService } from '../services/translation-corrections-educator.service';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import {
    ETranslationCorrectionSource,
    ITranslationCorrection,
    ITranslationCorrectionWord,
    ITranslationCorrectionFrequentWord,
} from '../../main/translation/interfaces/ITranslationCorrection';
import { SuggestionService } from '../../main/translation/suggestion/suggestion.service';
import { IVideo } from '../../main/spyder/interfaces/IVideo';
import { map } from 'rxjs/operators';
import { INLPToken } from '../../main/spyder/interfaces/ISubtitle';
import { IWord } from '../../main/spyder/interfaces/ITranslation';
import { TranslationCorrectionUtils } from '../../utils/translation-correction-utils';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { moveItemInFormArray } from '../../utils/move-item-in-form-array';
import { FrequentWordsService } from '../../main/translation/frequent-words/frequent-words.service';
import { CONSTANTS } from '../../constants';
import {
    OkCancelDialogData,
    OkCancelDialogComponent,
} from '../ok-cancel-dialog/ok-cancel-dialog.component';
import { IDict } from 'app/main/translation/interfaces/IDict';
import { TTranslationCorrectionWordType } from '../../main/translation/interfaces/ITranslationCorrection';
import * as _ from 'lodash';

export interface TranslationCorrectionDialogComponentData {
    translation: ITranslation;
    fromWord?: string;
    fromTok?: IWord;
    toWord: string;
    subtitles_index: number;
    toWords_index: number;
}

@Component({
    selector: 'translation-correction-dialog',
    templateUrl: './translation-correction-dialog.component.html',
    styleUrls: [
        './translation-correction-dialog.component.scss',
        '../styles/translation-correction-draggable.scss',
    ],
    encapsulation: ViewEncapsulation.None,
})
export class TranslationCorrectionDialogComponent implements OnInit {
    isLoading = true;
    suggestionForm: FormGroup;
    dialogTitle: string;
    presetColors = MatColors.presets;
    existingRule: ITranslationCorrection;
    translation: ITranslation;
    video: IVideo;

    suggestion: ITranslationCorrectionFrequentWord;
    typesFromExistingCorrections: TTranslationCorrectionWordType[];
    // formErrors: any;

    // readonly maxLengthToWord = 60; // Can get quite long because of all the meta information
    readonly maxWordsShownFrontend = CONSTANTS.MAX_TRANSLATION_WORDS_SHOW_FRONTEND; // How many words will be shown in the frontend

    /**
     * Constructor
     */
    constructor(
        public snackBar: MatSnackBar,
        public matDialogRef: MatDialogRef<TranslationCorrectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: TranslationCorrectionDialogComponentData,
        private formBuilder: FormBuilder,
        private translationCorrectionsEducatorService: TranslationCorrectionsEducatorService,
        private logger: NGXLogger,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private suggestionService: SuggestionService,
        public _frequentWordsService: FrequentWordsService,
        private _matDialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        translate.get('MTIT.suggest_other_translation').subscribe((i18n) => {
            this.dialogTitle = i18n;
        });
        this.translation = data.translation;
        
        if (this.data.fromTok?.text && this.data.fromTok?.pos) {
            const tok = this.data.fromTok;
            const nounPOS = ['NOUN', 'PROPN'];
            if (!nounPOS.includes(tok.pos)) {
                this.logger.debug(
                    `Lower-casing word: ${tok.text} (${tok.pos})`
                );
                tok.text = tok.text.toLowerCase();
                if (!_.isNil(tok.trText)) {
                    tok.trText = tok.trText.toLowerCase();
                }
            }
        }
    }

    ngOnInit(): void {
        this.logger.debug('Got data: ', this.data);
        this.suggestionForm = this.initForm();

        Promise.all([
            this.loadPendingCorrection(),
            this.loadDicts(),
            this.loadTypes(),
            // new Promise(resolve => setTimeout(resolve, 20_000))
        ]).then((res) => {
            this.logger.debug('Loaded pending corrections and dicts', res);
            this.suggestionForm = this.initForm();
            this.isLoading = false;
            const types = res[2];
            this.logger.debug('Got types (form):', this.suggestionForm.get('types').value);
            // // Set type if it has 
            // if (types && !this.suggestionForm.get('types').value) {
            //     this.suggestionForm.get('types').setValue(types);
            // }
        });
    }

    async loadPendingCorrection() {
        // Check if rule exists
        const res = await this.translationCorrectionsEducatorService.getTranslationSuggestion(
            this.translation.options.from,
            this.translation.options.to,
            'pending',
            this.data.fromTok?.text || this.data.fromWord
        );
        if (res.success) {
            this.existingRule = res.data;
            // const suggestionInfo = await this.suggestionService.getAllSuggestionInformation(
            //     this.existingRule
            // );
            // if (suggestionInfo) {
            //     this.translation = suggestionInfo.translation;
            //     this.video = suggestionInfo.video;
            // }
            return res.data;
        } else {
            return null;
        }
    }

    async loadDicts() {
        // Load dicts
        const token: INLPToken = this.data.fromTok ?? {
            text: this.data.fromWord,
            pos: undefined,
        };
        const words = await this.translationCorrectionsEducatorService.getDictsForToken(
            this.translation.options.from,
            this.translation.options.to,
            token,
            this.data.toWord,
            1,
            null
        );

        this.logger.debug('getDictsForToken got', words);
        if (words.data?.length > 0) {
            this.suggestion = words.data[0];
            return words.data[0];
        } else {
            return null;
        }
    }

    /**
     * Get the word type (coll., dial., austr.) for a certain word from exising translation corrections.
     * @returns array of types or null
     */
    async loadTypes() {
        // Load types
        if (!this.data.fromTok) {
            return;
        }
        const typesResponse = await this.translationCorrectionsEducatorService.getTypes(
            this.translation.options.from,
            this.translation.options.to,
            this.data.fromTok?.text,
            this.data.fromTok?.pos
        );

        this.logger.debug('loadTypes got', typesResponse);
        if (typesResponse) {
            this.typesFromExistingCorrections = typesResponse.types;
            return typesResponse.types;
        } else {
            return null;
        }
    }

    onCancelClick(): void {
        this.matDialogRef.close();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the form
     *
     * @returns {FormGroup}
     */
    initForm(): FormGroup {
        const toFixFormArray = this.suggestion
            ? TranslationCorrectionUtils.createToFixFormArray(this.suggestion)
            : [TranslationCorrectionUtils.formGroupForFixWord(null)];

        toFixFormArray.sort((a, b) => {
            if (a.value.show && !b.value.show) {
                return -1; // 'a' before'b'
            }

            if (!a.value.show && b.value.show) {
                return 1; // 'b' before 'a'
            }

            return 0;
        });

        // Take types from previous correction:
        let types = this.suggestion?.correction?.types;
        this.logger.debug('initForm got suggestion', this.suggestion);

        // If no types, try to extract from dicts:
        if (!types && this.suggestion) {
            const typesNew = TranslationCorrectionUtils.extractWordTypesFromDicts(
                this.suggestion.dicts,
                0.51
            );
            if (typesNew.length > 0) {
                types = typesNew;
            }
        }
        // If no types, get from existing corrections (in other languages):
        if (!types && this.typesFromExistingCorrections) {
            this.logger.debug('initForm() Setting types from existing corrections', this.typesFromExistingCorrections);
            types = this.typesFromExistingCorrections;
        }

        return this.formBuilder.group({
            // suggestion: ['', [Validators.required, Validators.maxLength(50)]],
            caseSensitive: {
                value: false,
                disabled: true,
            },
            comment: ['', [Validators.maxLength(200)]],
            toFix: new FormArray(toFixFormArray),
            types: [types],
        });
    }

    onSubmitClick(): void {
        if (
            TranslationCorrectionUtils.checkNumWordsTranslationStatusApprove(
                this.getNumWordsShown(),
                this._matDialog
            )
        ) {
            this.submitSuggestion();
        }
    }

    /**
     * Submit a translation correction
     */
    submitSuggestion(): void {
        // let toWords: string[] = this.suggestionForm.value.suggestion.split('/');
        // const toFix: ITranslationCorrectionWord[] = toWords.map((w) => {
        //     return {
        //         text: w.trim(),
        //         show: true,
        //         pos: undefined,
        //     };
        // });

        const value = this.suggestionForm.getRawValue();
        const toFix = value.toFix as ITranslationCorrectionWord[];
        toFix.forEach((tf) => {
            if (tf.gen === null) {
                delete tf.gen;
            }
            if (tf.pos === null) {
                delete tf.pos;
            }
            if (tf.subj === null || tf.subj.length === 0) {
                delete tf.subj;
            }
            if (tf.src === null) {
                delete tf.src;
            }
            if (tf.from === null) {
                delete tf.from;
            }
            if (tf.fromGen === null) {
                delete tf.fromGen;
            }
            delete tf['tn'];
        });

        let src = ETranslationCorrectionSource.USER;
        if (this.suggestion?.toFix?.length) {
            src = ETranslationCorrectionSource.USER_WITH_CORRECTION
        } else if (this.suggestion?.dicts?.find(dict => CONSTANTS.DICTIONARY_SRC.DICT_CC === dict.src)) {
            src = ETranslationCorrectionSource.USER_WITH_DICT
        }

        const requestBody: ITranslationCorrection = {
            video_id: this.translation.video_id,
            translation_id: this.translation._id,
            subtitles_index: this.data.subtitles_index,
            toWords_index: this.data.toWords_index,
            from: this.translation.options.from,
            to: this.translation.options.to,
            fromWord: this.data.fromTok?.text || this.data.fromWord,
            toWord: this.data.toWord,
            toFix,
            rule: 'override',
            rule_file: 'Postwords',
            case_sensitive: value.caseSensitive,
            comment: value.comment,
            pos: this.data.fromTok?.pos || undefined,
            lem: this.data.fromTok?.lemma || undefined,
            src,
            types: value.types?.length ? value.types : undefined,
            status: undefined,
            educator_id: undefined,
            version: undefined,
            admin_id: undefined,
            feedback: undefined,
        };
        this.translationCorrectionsEducatorService
            .sendTranslationCorrection(requestBody)
            .then(
                (res) => {
                    this.snackBar.open(res.msg, 'OK', {
                        // 'Suggestion successfully sent'
                        duration: 5000,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                },
                (err) => {
                    this.snackBar.open(
                        'Error submitting suggestion: ' + err.message || err,
                        'OK',
                        {
                            duration: 5000,
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                        }
                    );
                }
            );
        this.matDialogRef.close(['submit', this.suggestionForm]);
    }

    /**
     * Creates a text like "Word -> Word2 (approved)".
     * @param correction
     */
    getCorrectionShortText(correction?: ITranslationCorrection): string {
        if (!correction) {
            return '';
        }
        return (
            correction.fromWord +
            ' -> ' +
            TranslationCorrectionUtils.translationCorrectionToString(correction) +
            ' (' +
            correction.status +
            ')'
        );
    }

    get toFix(): FormArray {
        return this.suggestionForm.get('toFix') as FormArray;
    }

    addFixWord() {
        this.toFix.push(TranslationCorrectionUtils.formGroupForFixWord(null));
    }

    deleteFixWord(index: number) {
        const confirmDialogRef = this._matDialog.open(OkCancelDialogComponent, {
            width: '275px',
            data: {
                header: 'Remove translation',
                message: 'Are you sure you want to remove this translation?',
            } as OkCancelDialogData,
            disableClose: false,
        });

        confirmDialogRef.afterClosed().subscribe((result) => {
            this.logger.debug('The confirmation dialog was closed', result);
            if (result) {
                this.toFix.removeAt(index);
            }
        });
    }

    getNumWordsShown(): number {
        if (!this.toFix) {
            return 0;
        }
        const values = this.toFix.value as Array<any>;
        return values.filter((tf) => tf.show).length;
    }

    drop(event: CdkDragDrop<string[]>) {
        this.logger.debug('dop event', event);
        moveItemInFormArray(
            this.toFix,
            event.previousIndex,
            event.currentIndex
        );
    }

    getDictionaryTranslationsForLemma(): IDict[] {
        const apiSrc: number[] = [
            CONSTANTS.DICTIONARY_SRC.MS_TRANSLATOR_API,
            CONSTANTS.DICTIONARY_SRC.GOOGLE_CLOUD_TRANSLATION_API,
        ];
        const dicts = 
            this.suggestion?.dictsLemma?.filter(
                (d) => !apiSrc.includes(d.src)
            ) || [];
        // this.logger.debug('getDictionaryTranslationsForLemma', dicts);
        return dicts;
    }

    getDictionaryTranslations(): IDict[] {
        const apiSrc: number[] = [
            CONSTANTS.DICTIONARY_SRC.MS_TRANSLATOR_API,
            CONSTANTS.DICTIONARY_SRC.GOOGLE_CLOUD_TRANSLATION_API,
        ];
        const dicts = this.suggestion?.dicts?.filter(
            (d) => !apiSrc.includes(d.src)
        ) || [];
        // this.logger.debug('getDictionaryTranslations', dicts);
        return dicts;

    }

    getCorrectionsLemma(): ITranslationCorrectionWord[] {
        return this.suggestion?.correctionLemma?.toFix || [];
    }

    /**
     * Check if the NLP token from a subtitle matches the suggestion.
     * @param tok NLP token (this is actually an INLPToken but with shortened property names)
     */
    tokenMatchesSuggestions(tok: { t: string; p: string }) {
        return (
            tok.t.toLowerCase() === this.suggestion.fromWord.toLowerCase() &&
            tok.p === this.suggestion.pos
        );
    }

    toWordShow(toWord: any) {
        let toWords = toWord.split('/');
        toWords = toWords.map(word => word.trim()); // remove leading and trailing spaces
        toWords = toWords.filter(word => !word.startsWith('__START_NO_SHOW__') && !word.endsWith('__END_NO_SHOW__'));
        const toWordShow = toWords.join(' / ');
        return toWordShow;
    }
}
