import {IPageStateGlossary} from '../../users-and-customers/interfaces/IPageStateGlossary';
import {Resolve} from '@angular/router';
import {IGlossary} from '../interfaces/IGlossary';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';


@Injectable()
export class GlossaryWordsService implements Resolve<any> {
    pageState: IPageStateGlossary;

    constructor(private _httpClient: HttpClient) {
    }


    /**
     * Resolver
     *
     * @returns {Promise<any>}
     */
    resolve(): Promise<IPageStateGlossary> {
        const pageSize = 50;
        const pageIndex = 0;

        return new Promise((resolve, reject) => {
            const state = this.pageState;
            if (state) {
                this.pageState = null;
                resolve(state);
                return;
            }

            this.getWordsList(pageSize, pageIndex).then(data => {
                const initState: IPageStateGlossary = {
                    pageIndex,
                    pageSize,
                    total: data.count,
                    scrollTop: 0,
                    glossaryWords: data.data
                };
                resolve(initState);
            }).catch(err => {
                reject(err);
            });
        });
    }

    /**
     * Get the glossary words
     *
     * @returns {Promise<IGlossary[]>}
     */
    getWordsList(limit: number, offset: number, search?: string, select = ''): Promise<{data: IGlossary[], count: number, success: boolean}> {
        let params = new HttpParams()
            .set('limit', '' + limit)
            .set('offset', '' + offset)
            .set('sort', 'name')
            .set('select', select);
        if (search) {
            params = params.set('search', search);
        }
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(`${environment.serviceApiBaseUrl}/glossary`, {params})
                .subscribe(
                    (response) => {
                        resolve(response as {data: IGlossary[], count: number, success: boolean});
                    },
                    (error) => {
                        reject(error.error || error);
                    }
                );
        });
    }
}

