import { Pipe, PipeTransform } from "@angular/core";

/**
 * Transforms a number (seconds) to format HH:mm:ss
 */
@Pipe({
    name: "duration",
})
export class DurationPipe implements PipeTransform {
    transform(n: number) {
        return (
            Math.floor(n / 3600)
                .toString()
                .padStart(2, "0") +
            ":" +
            Math.floor((n % 3600) / 60)
                .toString()
                .padStart(2, "0") +
            ":" +
            Math.floor((n % 3600) % 60)
                .toString()
                .padStart(2, "0")
        );
    }
}
