import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {FormUtils} from '../../../utils/form-utils';
import {SuggestionService} from '../suggestion/suggestion.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {IGlossary} from '../interfaces/IGlossary';
import {GlossaryWordService} from './ideal.service';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OkCancelDialogComponent, OkCancelDialogData} from '../../../shared/ok-cancel-dialog/ok-cancel-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {MAX_LENGTH_WORD} from '../../../utils/translation-correction-utils';


@Component({
  selector: 'app-ideal',
  templateUrl: './ideal.component.html',
  styleUrls: ['./ideal.component.scss']
})
export class IdealComponent implements OnInit {
    @ViewChild('inputUrl') inputId: ElementRef;

    glossaryForm: FormGroup;
    glossary: IGlossary = {
       definition: '', exampleSentences: [], type: '', word: ''
    };
    formErrors: any;
    isProduction = true;
    urlRegex = /^(?:http(?:s)?:\/\/)?[\w.-]+(?:\.[äÄöÖüÜß%\w\.-]+)+[äÄöÖüÜß%\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    private _unsubscribeAll: Subject<any>;
    public submitting = false;
    copied = false;

    constructor(
        private _formBuilder: FormBuilder,
        private _router: Router,
        private snackBar: MatSnackBar,
        private _route: ActivatedRoute,
        private logger: NGXLogger,
        private _fuseProgressBarService: FuseProgressBarService,
        public _suggestionService: SuggestionService,
        private _idealService: GlossaryWordService,
        private _matDialog: MatDialog
    ) {
        this.formErrors = {
            word: {},
            definition: {},
            type: {},
            nominativ_singular: {},
            nominativ_plural: {},
            verben_present_person_ich: {},
            verben_present_person_du: {},
            verben_present_person_er_sie_es: {},
            verben_present_person_wir: {},
            verben_present_person_ihr: {},
            verben_present_person_sie: {},
            verben_imperativ_person_singular: {},
            verben_imperativ_person_plural: {},
            verben_perfekt_partizip_2: {},
            verben_hilfsverb: {},
            adjektkiv_positiv: {},
            adjektiv_komparativ: {},
            adjektiv_superlativ: {},
            genitiv_singular: {},
            genitiv_plural: {},
            dativ_singular: {},
            dativ_plural: {},
            akkusativ_singular: {},
            akkusativ_plural: {},
            source_url_1: {},
            source_url_2: {},
            source_text_1: {},
            source_text_2: {},
            source_text_3: {},
        };

        this._unsubscribeAll = new Subject();
    }


    ngOnInit(): void {
        this.glossary = this._route.snapshot.data.data.glossary;
        this.glossaryForm = this.initForm();

        // Subscribe to valueChanges only once, not inside itself
        // this.glossaryForm.valueChanges
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(() => {
        //         // Call the method to update validation
        //         this.updateNominativValidation();
        //
        //         // Handle form errors
        //         // this.onFormValuesChanged();
        //     });

        // this.glossaryForm.get('type').valueChanges
        //     .subscribe(value => {
        //         this.glossaryForm.get('nominativ_singular').updateValueAndValidity();
        //     });
        if (this.glossary && this.glossary.type) {
            const storedType =  this.glossary.type;
            this.clearAllValidators();
            if (storedType === 'Nominativ') {
                this.setNominativValidators();
            } else if (storedType === 'Verben') {
                this.setVerbenValidators();
            } else if (storedType === 'Adjektiv') {
                this.setAdjektivValidators();
            }

        }
        this.glossaryForm.get('type').valueChanges.subscribe((selectedType) => {
            // Clear all validators first
            this.clearAllValidators();

            if (selectedType === 'Nominativ') {
                this.setNominativValidators();
            } else if (selectedType === 'Verben') {
                this.setVerbenValidators();
            } else if (selectedType === 'Adjektiv') {
                this.setAdjektivValidators();
            }

            // Update validity of form controls
            this.updateFormValidity();
        });


        console.log('Initialized form group', this.glossaryForm);
    }

    clearAllValidators(): void {
        const fieldsToClear = [
            'nominativ_singular',
            'nominativ_plural',
            'genitiv_singular',
            'genitiv_plural',
            'dativ_singular',
            'dativ_plural',
            'akkusativ_singular',
            'akkusativ_plural',
            'verben_present_person_ich',
            'verben_present_person_du',
            'verben_present_person_er_sie_es',
            'verben_present_person_wir',
            'verben_present_person_ihr',
            'verben_present_person_sie',
            'verben_imperativ_person_singular',
            'verben_imperativ_person_plural',
            'verben_perfekt_partizip_2',
            'verben_hilfsverb',
            'adjektkiv_positiv',
            'adjektiv_komparativ',
            'adjektiv_superlativ',
        ];

        fieldsToClear.forEach(fieldName => {
            const control = this.glossaryForm.get(fieldName);
            if (control) {
                control.clearValidators();
                control.updateValueAndValidity();
            }
        });
    }

    setNominativValidators(): void {
        this.glossaryForm.controls.nominativ_singular.setValidators([Validators.required]);
        this.glossaryForm.controls.nominativ_plural.setValidators([Validators.required]);
        this.glossaryForm.controls.genitiv_singular.setValidators([Validators.required]);
        this.glossaryForm.controls.genitiv_plural.setValidators([Validators.required]);
        this.glossaryForm.controls.dativ_singular.setValidators([Validators.required]);
        this.glossaryForm.controls.dativ_plural.setValidators([Validators.required]);
        this.glossaryForm.controls.akkusativ_singular.setValidators([Validators.required]);
        this.glossaryForm.controls.akkusativ_plural.setValidators([Validators.required]);

        this.glossaryForm.controls.nominativ_singular.updateValueAndValidity();
        this.glossaryForm.controls.nominativ_plural.updateValueAndValidity();
        this.glossaryForm.controls.genitiv_singular.updateValueAndValidity();
        this.glossaryForm.controls.genitiv_plural.updateValueAndValidity();
        this.glossaryForm.controls.dativ_singular.updateValueAndValidity();
        this.glossaryForm.controls.dativ_plural.updateValueAndValidity();
        this.glossaryForm.controls.akkusativ_singular.updateValueAndValidity();
        this.glossaryForm.controls.akkusativ_plural.updateValueAndValidity();
    }

    setVerbenValidators(): void {
        this.setRequiredValidators([
            'verben_present_person_ich',
            'verben_present_person_du',
            'verben_present_person_er_sie_es',
            'verben_imperativ_person_singular',
            'verben_imperativ_person_plural',
            'verben_perfekt_partizip_2',
            'verben_present_person_wir',
            'verben_present_person_ihr',
            'verben_present_person_sie',
            'verben_hilfsverb',
        ]);
    }

    setAdjektivValidators(): void {
        this.setRequiredValidators([
            'adjektkiv_positiv',
            'adjektiv_komparativ',
            'adjektiv_superlativ',
        ]);
    }

    setRequiredValidators(fieldNames: string[]): void {
        fieldNames.forEach(fieldName => {
            const control = this.glossaryForm.get(fieldName);
            if (control) {
                control.setValidators([Validators.required]);
                control.updateValueAndValidity();
            }
        });
    }

    updateFormValidity(): void {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }

            const control = this.glossaryForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    /**
     * Initializes form with EMPTY values.
     *
     * @returns {FormGroup}
     */
    initForm(): FormGroup {
        const urlValidator: ValidatorFn = (control) => {
            if (!control.value) {
                return null; // If the value is empty, consider it valid (optional field)
            }

            const urlPattern = /^https?:\/\/\S+$/i; // Basic URL pattern (http:// or https:// followed by any non-whitespace characters)
            if (!urlPattern.test(control.value)) {
                return { invalidUrl: true }; // Return an error object if the URL pattern does not match
            }

            return null; // If the value matches the URL pattern, consider it valid
        };

        const linkedWordsArray = this.glossary && this.glossary.linkedWords ? this.glossary.linkedWords.map(id => this.formGroupForLinkedIdWord(id)) : [];
        return this._formBuilder.group({
            _id: [this.glossary && this.glossary._id ? this.glossary._id : ''],
            word: [this.glossary && this.glossary.word ? this.glossary.word : '', [Validators.required, Validators.maxLength(80)]],
            definition: [this.glossary && this.glossary.definition ? this.glossary.definition : '', [Validators.required, Validators.maxLength(250)]],
            type: [this.glossary && this.glossary.type ? this.glossary.type : 'Nominativ', [Validators.required]],
            nominativ_singular: [this.glossary && this.glossary.nominativ_singular ? this.glossary.nominativ_singular : '', [Validators.required]],
            nominativ_plural: [this.glossary && this.glossary.nominativ_plural ? this.glossary.nominativ_plural : '', [Validators.required]],
            genitiv_singular: [this.glossary && this.glossary.genitiv_singular ? this.glossary.genitiv_singular : '', [Validators.required]],
            genitiv_plural: [this.glossary && this.glossary.genitiv_plural ? this.glossary.genitiv_plural : '', [Validators.required]],
            dativ_singular: [this.glossary && this.glossary.dativ_singular ? this.glossary.dativ_singular : '', [Validators.required]],
            dativ_plural: [this.glossary && this.glossary.dativ_plural ? this.glossary.dativ_plural : '', [Validators.required]],
            akkusativ_singular: [this.glossary && this.glossary.akkusativ_singular ? this.glossary.akkusativ_singular : '', [Validators.required]],
            akkusativ_plural: [this.glossary && this.glossary.akkusativ_plural ? this.glossary.akkusativ_plural : '', [Validators.required]],
            exampleSentence1: [this.glossary?.exampleSentences?.[0] || '', Validators.maxLength(250)],
            exampleSentence2: [this.glossary?.exampleSentences?.[1] || '', Validators.maxLength(250)],
            exampleSentence3: [this.glossary?.exampleSentences?.[2] || '', Validators.maxLength(250)],
            exampleSentence4: [this.glossary?.exampleSentences?.[3] || '', Validators.maxLength(250)],
            source_url_1: [this.glossary?.source_url_1 || '', [Validators.maxLength(150), Validators.pattern(this.urlRegex)]],
            source_url_2: [this.glossary?.source_url_2 || '', [Validators.maxLength(150), Validators.pattern(this.urlRegex)]],
            source_text_1: [this.glossary?.source_text_1 || '', [Validators.maxLength(150)]],
            source_text_2: [this.glossary?.source_text_2 || '', [Validators.maxLength(150)]],
            source_text_3: [this.glossary?.source_text_3 || '', [Validators.maxLength(150)]],
            // New fields added
            verben_present_person_ich: [this.glossary?.verben_present_person_ich || '', [Validators.maxLength(150)]],
            verben_present_person_du: [this.glossary?.verben_present_person_du || '', [Validators.maxLength(150)]],
            verben_present_person_er_sie_es: [this.glossary?.verben_present_person_er_sie_es || '', [Validators.maxLength(150)]],
            verben_present_person_wir: [this.glossary?.verben_present_person_wir || '', [Validators.maxLength(150)]],
            verben_present_person_ihr: [this.glossary?.verben_present_person_ihr || '', [Validators.maxLength(150)]],
            verben_present_person_sie: [this.glossary?.verben_present_person_sie || '', [Validators.maxLength(150)]],
            verben_imperativ_person_singular: [this.glossary?.verben_imperativ_person_singular || '', [Validators.maxLength(150)]],
            verben_imperativ_person_plural: [this.glossary?.verben_imperativ_person_plural || '', [Validators.maxLength(150)]],
            verben_perfekt_partizip_2: [this.glossary?.verben_perfekt_partizip_2 || '', [Validators.maxLength(150)]],
            verben_hilfsverb: [this.glossary?.verben_hilfsverb || '', [Validators.maxLength(150)]],
            adjektkiv_positiv: [this.glossary?.adjektkiv_positiv || '', [Validators.maxLength(150)]],
            adjektiv_komparativ: [this.glossary?.adjektiv_komparativ || '', [Validators.maxLength(150)]],
            adjektiv_superlativ: [this.glossary?.adjektiv_superlativ || '', [Validators.maxLength(150)]],
            linkedWords: new FormArray(linkedWordsArray),
        });
    }

    /**
     * On form values changed
     */
    onFormValuesChanged(): void {
        // Handle form errors
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.formErrors[field] = {};

            // Get the control
            const control = this.glossaryForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    get formValidationErrors(): any {
        return FormUtils.getFormValidationErrors(this.glossaryForm);
    }

    /**
     * On customer form submit
     */
    onSubmitGlossaryWordForm(): void {
        console.log('onSubmitGlossaryWordForm:: ', this.glossaryForm.value);
        if (this.glossary) {
            this.createOrUpdateWord(true);
        } else {
            this.createOrUpdateWord();
        }
    }


    async createOrUpdateWord(shouldUpdate = false): Promise<any> {
        const val = this.glossaryForm.value;

        const {
            word,
            definition,
            type,
            nominativ_singular,
            nominativ_plural,
            genitiv_singular,
            genitiv_plural,
            dativ_singular,
            dativ_plural,
            akkusativ_singular,
            akkusativ_plural,
            exampleSentence1,
            exampleSentence2,
            exampleSentence3,
            exampleSentence4,
            verben_present_person_ich,
            verben_present_person_du,
            verben_present_person_er_sie_es,
            verben_hilfsverb,
            verben_present_person_sie,
            verben_present_person_ihr,
            verben_present_person_wir,
            verben_imperativ_person_singular,
            verben_imperativ_person_plural,
            verben_perfekt_partizip_2,
            adjektkiv_positiv,
            adjektiv_komparativ,
            adjektiv_superlativ,
            linkedWords,
            source_url_1,
            source_url_2,
            source_text_1,
            source_text_2,
            source_text_3
        }: {
            word: string;
            definition: string;
            type: 'enum1' | 'enum2' | 'enum3';
            nominativ_singular?: string;
            nominativ_plural?: string;
            genitiv_singular?: string;
            genitiv_plural?: string;
            dativ_singular?: string;
            dativ_plural?: string;
            akkusativ_singular?: string;
            akkusativ_plural?: string;
            exampleSentence1?: string;
            exampleSentence2?: string;
            exampleSentence3?: string;
            exampleSentence4?: string;
            verben_present_person_ich?: string;
            verben_present_person_du?: string;
            verben_present_person_er_sie_es?: string;
            verben_hilfsverb?: string;
            verben_present_person_sie?: string;
            verben_present_person_ihr?: string;
            verben_present_person_wir?: string;
            verben_imperativ_person_singular?: string;
            verben_imperativ_person_plural?: string;
            verben_perfekt_partizip_2?: string;
            adjektkiv_positiv?: string;
            adjektiv_komparativ?: string;
            adjektiv_superlativ?: string;
            linkedWords?: string[];
            source_url_1?: string;
            source_url_2?: string;
            source_text_1?: string;
            source_text_2?: string;
            source_text_3?: string;
        } = val;

        const exampleSentences = [
            exampleSentence1,
            exampleSentence2,
            exampleSentence3,
            exampleSentence4
        ].filter(sentence => !!sentence);

        const newGlossary: IGlossary = {
            word,
            definition,
            type,
            nominativ_singular,
            nominativ_plural,
            genitiv_singular,
            genitiv_plural,
            dativ_singular,
            dativ_plural,
            akkusativ_singular,
            akkusativ_plural,
            exampleSentences,
            verben_present_person_ich,
            verben_present_person_du,
            verben_present_person_er_sie_es,
            verben_hilfsverb,
            verben_present_person_sie,
            verben_present_person_ihr,
            verben_present_person_wir,
            verben_imperativ_person_singular,
            verben_imperativ_person_plural,
            verben_perfekt_partizip_2,
            adjektkiv_positiv,
            adjektiv_komparativ,
            adjektiv_superlativ,
            linkedWords,
            source_url_1,
            source_url_2,
            source_text_1,
            source_text_2,
            source_text_3
        };

        try {
            this.submitting = true;
            this._fuseProgressBarService.show();
            let result;
            if (shouldUpdate) {
                result = await this._idealService.updateWord(this.glossary._id, newGlossary);
            } else {
                result = await this._idealService.createWord(newGlossary);
            }
            this._fuseProgressBarService.hide();
            this._router.navigate(['translation/glossary'], { queryParams: { r: true } }).then(() => {
                const msg = shouldUpdate ? 'Updated word correctly' : 'Saved word successfully';
                this.snackBar.open(msg, 'OK', {
                    duration: 5000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
            });
        } catch (e) {
            this._fuseProgressBarService.hide();
            console.log('error creating the word ', e);
        }
    }

    copyToClipBoard(inputElement): void {
        this.copied = true;
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
        setTimeout(() => {
            this.copied = false;
        }, 3000);
    }

    get linkedWords(): FormArray {
        return this.glossaryForm.get('linkedWords') as FormArray;
    }

    deleteLinkedWord(index: number): void {
        const confirmDialogRef = this._matDialog.open(OkCancelDialogComponent, {
            width: '275px',
            data: {
                header: 'Remove translation',
                message: 'Are you sure you want to remove this id ?',
            } as OkCancelDialogData,
            disableClose: false,
        });

        confirmDialogRef.afterClosed().subscribe((result) => {
            this.logger.debug('The confirmation dialog was closed', result);
            if (result) {
                this.linkedWords.removeAt(index);
            }
        });
    }


    formGroupForLinkedIdWord(
        text: string,
    ): FormGroup {
        return new FormGroup({
            text: new FormControl(text, [
                Validators.required,
                Validators.maxLength(MAX_LENGTH_WORD),
            ]),
        });
    }

    addLinkedWord(): void {
        this.linkedWords.push(this.formGroupForLinkedIdWord(null));
    }

}
