import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import {DuplicatedVideosService} from '../../../app/main/spyder/duplicates/duplicated-videos.service';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     * @param _duplicatesService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _duplicatesService: DuplicatedVideosService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        const spyderNavigation = this.navigation[0].children.find(navigation => navigation.id === 'spyder');
        if (spyderNavigation) {
            const children = spyderNavigation.children;
            const duplicateNav = children.find(nav => nav.id === 'duplicates');
            const countDuplicates = await this._duplicatesService.getDuplicatesCount();

            if (countDuplicates && countDuplicates.hasDuplicates) {
                // If there are duplicates, find the duplicateNav in the this.navigation and update showBlueDot property to true
                const duplicateNavInNavigation = this.navigation[0].children.find(nav => nav.id === 'spyder').children.find(nav => nav.id === 'duplicates');
                if (duplicateNavInNavigation) {
                    duplicateNavInNavigation.showBlueDot = true;
                    if (countDuplicates.firstWebSourceId) {
                        duplicateNavInNavigation.url = `${duplicateNavInNavigation.url}/${countDuplicates.firstWebSourceId}`;
                    } else {
                        console.log(' cant change the url because websource id is not coming');
                    }
                }
            }
        }
    // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }
}
