import { Injectable } from '@angular/core';
import { IWebsource } from './interfaces/IWebsource';
import { IVideo } from './interfaces/IVideo';

/**
 * The state of the saved and crawled videos pages.
 */
export class PageState {
    selectedWebSource: IWebsource;
    webSources: IWebsource[];
    videos: IVideo[];
    total: number;
    pageIndex: number;
    pageSize: number;
    search?: string;
    scrollTop: number;
    selectedVideos: string[];
}

export interface IVideoCategorySelect {
    value: string;
    viewValue: string;
}

@Injectable()
export class SpyderDataService {

    readonly DEFAULT_WEBSOURCE_ID = 'orftvthek';

    readonly PREF_KEY_WEBSOURCE_ID = 'pref_websource_id';

    cachedPageState: PageState;
    savedPageState: PageState;
    videoDetails: IVideo;
    webSources: IWebsource[];
    backRoute: string;

    /**
     * Constructor
     */
    constructor() {

    }

    get webSource(): IWebsource {
        // console.log('get webSource()');
        const prefWebsourceId = localStorage.getItem(this.PREF_KEY_WEBSOURCE_ID);
        if (prefWebsourceId) {
            return this.webSources.find(ws => ws.id === prefWebsourceId);
        } else {
            return this.webSources.find(ws => ws.id === this.DEFAULT_WEBSOURCE_ID); // Fallback, if nothing is set
        }
    }

    set webSource(webSource: IWebsource) {
        // console.log('set webSource()', webSource.id);
        localStorage.setItem(this.PREF_KEY_WEBSOURCE_ID, webSource.id);
    }

    /**
     * The available video categories.
     * TODO this should be loaded via the API from GET /v3/videocategories instead.
     */
    get videoCategoriesSelect(): Array<IVideoCategorySelect> {
        return [
            {value: '5e599ea5ad0ff37ecfb8f8c7', viewValue: 'Documentary'},
            {value: '5e5ceb93f023460e1ddf5d5f', viewValue: 'Film & Series'},
            {value: '659e98bc179c0bcc7d30b07e', viewValue: 'News & Current Topics'},
            {value: '659ef812f29c11752a73218c', viewValue: 'News & Current Topics'},
            {value: '5e5cebe4f023460e1ddf5d60', viewValue: 'Local'},
            {value: '5e5ced843a66040eaa718030', viewValue: 'Magazine'},
            {value: '5e5cf0ce3a66040eaa718031', viewValue: 'Kids'},
            {value: '5e5cf0ea3a66040eaa718032', viewValue: 'Culture & Music'},
            {value: '5e5cf1033a66040eaa718033', viewValue: 'Entertainment'},
            {value: '5e5cf11d3a66040eaa718034', viewValue: 'Sport'},
            {value: '5e5cf13c3a66040eaa718035', viewValue: 'Comedy & Talk'},
            {value: '5e5cf1543a66040eaa718036', viewValue: 'Other'},
        ];
    }

    /**
     * The available subjects.
     * TODO this should be loaded via the API from GET /v3/subjects instead.
     * @deprecated use videocategories from the API instead
     */
    get subjectsSelect(): Array<IVideoCategorySelect> {
        return [
            {value: '5e99f4bd0f5da290b03c4893', viewValue: 'Berufsorientierung'},
            {value: '5e99f4bd0f5da290b03c4894', viewValue: 'Bildnerische Erziehung & Kunst'},
            {value: '5e99f4bd0f5da290b03c4895', viewValue: 'Biologie & Umweltkunde'},
            {value: '5e99f4bd0f5da290b03c4896', viewValue: 'Chemie'},
            {value: '5e99f4bd0f5da290b03c4897', viewValue: 'Deutsch'},
            {value: '5e99f4bd0f5da290b03c4898', viewValue: 'Deutsch als Zweit- / Fremdsprache'},
            {value: '5e99f4bd0f5da290b03c4899', viewValue: 'Englisch'},
            {value: '5e99f4bd0f5da290b03c489a', viewValue: 'Ernährung & Haushalt'},
            {value: '5e99f4bd0f5da290b03c489b', viewValue: 'Ethik'},
            {value: '5e99f4bd0f5da290b03c489c', viewValue: 'Französisch'},
            {value: '5e99f4bd0f5da290b03c489d', viewValue: 'Geographie / Erdkunde'},
            {value: '5e99f4bd0f5da290b03c489e', viewValue: 'Geschichte'},
            {value: '5e99f4bd0f5da290b03c489f', viewValue: 'Informatik & IKT'},
            {value: '5e99f4bd0f5da290b03c48a0', viewValue: 'Italienisch'},
            {value: '5e99f4bd0f5da290b03c48a9', viewValue: 'Medienerziehung'},
            {value: '5e99f4bd0f5da290b03c48a1', viewValue: 'Mathematik'},
            {value: '5e99f4bd0f5da290b03c48a2', viewValue: 'Musik'},
            {value: '5e99f4bd0f5da290b03c48a3', viewValue: 'Physik'},
            {value: '5e99f4bd0f5da290b03c48aa', viewValue: 'Politische Bildung'},
            {value: '5e99f4bd0f5da290b03c48a4', viewValue: 'Psychologie, Philosophie & Pädagogik'},
            {value: '5e99f4bd0f5da290b03c48a5', viewValue: 'Religion'},
            {value: '5e99f4bd0f5da290b03c48a6', viewValue: 'Sozialkunde & Gemeinschaftskunde'},
            {value: '5e99f4bd0f5da290b03c48a7', viewValue: 'Spanisch'},
            {value: '5e99f4bd0f5da290b03c48a8', viewValue: 'Technik'},
            {value: '5e99f4bd0f5da290b03c48ab', viewValue: 'Wirtschaft & Betriebswirtschaft'},
        ];
    }
}
