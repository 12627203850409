// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    spyderBaseUrl:      'https://spyder3-dev.uugot.it/v3',
    serviceApiBaseUrl:  'https://service-api-dev.uugot.it/v3',
    frontendBaseUrl:    'https://app-dev.uugot.it',

    // Auth0 is not being used any more:
    auth: {
        clientID: 'ks7PJ9GVzi0hVYffLEutFUY7qBGTEg1h',
        domain: 'uugotit.eu.auth0.com',
        audience: 'https://uugotit.eu.auth0.com/api/v2/',
        returnTo: 'http://localhost:4200/login',
        scope: 'openid profile email'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
