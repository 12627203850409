import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {NGXLogger} from 'ngx-logger';
import { ILanguage } from '../interfaces/ILanguage';
import { LanguageManagementToolService } from './language-management-tool.service';

@Component({
  selector: 'app-language-management-tool',
  templateUrl: './language-management-tool.component.html',
  styleUrls: ['./language-management-tool.component.scss'],
  animations: fuseAnimations
})

export class LanguageManagementToolComponent implements OnInit {
  form: FormGroup;
  language: ILanguage = {};

  statuses = ['Approved', 'Beta', 'Not available'];
  states = [
    {value: true, description: 'Active'},
    {value: false, description: 'Inactive'}
  ];

  constructor(private _formBuilder: FormBuilder, private _route: ActivatedRoute, public snackBar: MatSnackBar,
              private logger: NGXLogger, private _languagesManagementToolService: LanguageManagementToolService) {
    this.logger.debug('Language snapshot data:', this._route.snapshot.data);
    // Get data from resolver
    this.language = this._route.snapshot.data.data.language;

    this.form = this._formBuilder.group({
      name: [this.language.name || '', [Validators.required]],
      // code: [this.language.code || '', [Validators.disabled]],
      code: new FormControl({ value: this.language.code || '', disabled: true }),
      is_active: [this.language.is_active || '', [Validators.required]],
      status: [this.language.status || '', [Validators.required]],
  });
  }

  ngOnInit(): void {
  }

  onSubmitLanguageForm(): void {
    // console.log(this.form.value);
    if (this.language) {
      this.updateLanguage();
    }
  }

  /**
   * Updates existing language
   */
  updateLanguage(): void {
    this._languagesManagementToolService.updateLanguage(this.form.value, this.language.code).then(response => {
      const successSnack = () => {
        this.snackBar.open('The language was updated successfully', 'OK', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      };
      successSnack();
    }, err => {
      console.log(err.message);
      this.snackBar.open('Error: ' + err.message || err, 'OK', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });
  }

}
