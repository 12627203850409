<div id="user" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
            fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/languages/languages-management-tool'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start">
                    <h1 [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        Update Language
                    </h1>
                </div>
            </div>

            <button mat-raised-button [disabled]="form && !form.valid" form="form">
                Save
            </button>
        </div>

        <!-- CONTENT -->
        <div class="content-card mat-white-bg" fusePerfectScrollbar>
            <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-md="row">
                <!-- REACTIVE FORM EXAMPLE -->

                <form class="mat-white-bg p-24 mb-24 w-100-p" fxLayout="column" fxFlex name="form" id="form"
                    [formGroup]="form" (ngSubmit)="onSubmitLanguageForm()">

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100">
                            <input matInput [placeholder]="'Language'" [value]="language.name" formControlName="name">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100">
                            <input matInput [placeholder]="'Code'" [value]="language.code" formControlName="code">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="100">
                            <mat-select [placeholder]="'Status'" formControlName="status">
                                <div *ngFor="let status of statuses">
                                    <mat-option [value]="status">
                                        {{status}}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>


