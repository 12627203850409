import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { FuseSharedModule } from '@fuse/shared.module';
import { TranslationCorrectionsEducatorService } from './services/translation-corrections-educator.service';
import { TranslationCorrectionDialogComponent } from './translation-correction-dialog/translation-correction-dialog.component';
import { SuggestionService } from 'app/main/translation/suggestion/suggestion.service';
import { SuggestionsService } from './services/suggestions.service';
import { FrequentWordsService } from '../main/translation/frequent-words/frequent-words.service';
import { DurationPipe } from './pipes/duration.pipe';
import { OkCancelDialogComponent } from './ok-cancel-dialog/ok-cancel-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { VjsPlayerComponent } from './vjs-player/vjs-player.component';


const routes = [
];

@NgModule({
    declarations: [
        TranslationCorrectionDialogComponent,
        DurationPipe,
        OkCancelDialogComponent,
        VjsPlayerComponent
    ],
    imports : [
        RouterModule.forChild(routes),

        TranslateModule,

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatCardModule,
        MatDialogModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressBarModule,

        FuseSharedModule,

        DragDropModule
    ],
    exports: [
        DurationPipe,
        VjsPlayerComponent
    ],
    providers: [
        TranslationCorrectionsEducatorService,
        SuggestionService,
        SuggestionsService,
        FrequentWordsService
    ],
    entryComponents: [
        TranslationCorrectionDialogComponent,
        OkCancelDialogComponent
    ]
})
export class SharedModule
{
}
