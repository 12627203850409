import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { TranslateModule } from '@ngx-translate/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginModule } from 'app/main/login/login.module';
import { TrtestModule } from 'app/main/translation/trtest/trtest.module';
import { LanguagesModule } from 'app/main/languages/languages.module';
import { SharedModule } from 'app/shared/shared.module';
import { JwtTokenInterceptor } from './auth/jwtTokenInterceptor.service';
import { AuthErrorHandler } from './auth/auth-error-handler';
import { environment } from 'environments/environment';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Register additional locales:
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import { IdealComponent } from './main/translation/ideal/ideal.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { GlossaryWordsComponent } from './main/translation/glossary-words/glossary-words.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
registerLocaleData(localeDE);

const appRoutes: Routes = [
    {
        path: 'spyder',
        loadChildren: () => import('./main/spyder/spyder.module').then(m => m.SpyderModule)
    },
    {
        path: 'users-and-customers',
        loadChildren: () => import('./main/users-and-customers/users-and-customers.module').then(m => m.UsersModule)
    },
    {
        path: 'translation',
        loadChildren: () => import('./main/translation/translation.module').then(m => m.TranslationModule)
    },
    {
        path: 'scooling',
        loadChildren: () => import('./main/scooling/scooling.module').then(m => m.ScoolingModule)
    },
    {
        path: 'languages',
        loadChildren: () => import('./main/languages/languages.module').then(m => m.LanguagesModule)
    },
    {
        path: '**',
        redirectTo: 'spyder/crawl-status'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        IdealComponent,
        GlossaryWordsComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),

        TranslateModule.forRoot(),
        LanguagesModule,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        LoginModule,
        TrtestModule,
        SharedModule,


        LoggerModule.forRoot({
            level: environment.production ? NgxLoggerLevel.WARN : NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.OFF,
            serverLoggingUrl: '/api/logs',
            enableSourceMaps: true,
            timestampFormat: 'HH:mm:ss.SSS'
        }),
        DragDropModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatRadioModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtTokenInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: AuthErrorHandler
        },
        {
            provide: MAT_DATE_LOCALE, // See https://material.angular.io/components/datepicker/overview
            // useValue: 'en-ca'
            useValue: 'de'
        },
        {
            provide: LOCALE_ID, // See https://angular.io/api/core/LOCALE_ID
            useValue: 'de-AT'
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
