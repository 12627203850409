export const locale = {
    lang: 'de',
    data: {
        'NAV': {
            'APPLICATIONS': 'Anwendungen',
            'USERS': {
                'TITLE': 'Benutzer',
                // 'BADGE': '15'
            },
            'SPYDER': 'Spyder',
            'WEBSOURCES': {
                'TITLE': 'Webquellen',
                // 'BADGE': '3'
            }
        }
    }
};
