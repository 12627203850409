import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { fuseAnimations } from '@fuse/animations';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../constants';


@Component({
    selector: 'trtest',
    templateUrl: './trtest.component.html',
    styleUrls: ['./trtest.component.scss'],
    animations: fuseAnimations
})
export class TrtestComponent implements OnInit {
    trFG: FormGroup;
    apiResponse: any;
    errMsg: string;
    okMsg: string;
    processing = false;

    readonly availableTranslationLanguages = CONSTANTS.AVAILABLE_TRANSLATION_LANGUAGES;
    availableToLanguages: any[]; // Declare the property to hold available languages

    constructor(
        private fb: FormBuilder,
        private httpClient: HttpClient,
    ) {}


    ngOnInit(): void {
        this.availableToLanguages = [...this.availableTranslationLanguages['de']];
        // initialize formgroup
        this.trFG = this.fb.group({
            from: ['de', Validators.required],
            to: ['en', Validators.required],
            oriText: ['', Validators.required],
            preprocessText: true,
            preprocessWords: true,
            process: 'msTranslator',
            processWithAlignment: false,
            processText: false,
            postprocessText: true,
            postprocessWords: true,

        });
        this.trFG.get('from').valueChanges.subscribe((selectedFromLanguage: string) => {
            this.availableToLanguages = this.availableTranslationLanguages[selectedFromLanguage];
        });
        // console.log(this.trFG);
    }

    translationTest(): void {
        this.processing = true;
        this.apiResponse = 'Processing. Please wait ...';
        const tr = this.trFG.value;
        // console.log(tr);
        const options = {
            from: tr.from,
            to: tr.to,
            preprocessText: tr.preprocessText,
            preprocessWords: tr.preprocessWords,
            process: tr.process,
            processWithAlignment: tr.processWithAlignment,
            processText: tr.processText,
            postprocessText: tr.postprocessText,
            postprocessWords: tr.postprocessWords
        };
        const oriText = tr.oriText;

        this.httpClient.post(`${environment.serviceApiBaseUrl}/translations/test`, {options, oriText})
          .subscribe((apiResp: any) => {
                // console.log(apiResp);
                this.processing = false;
                this.errMsg = '';
                this.okMsg = apiResp.msg;
                this.apiResponse = apiResp;
          }, (e: HttpErrorResponse) => {
                console.error(e);
                this.errMsg = e.error.message || e.error;
          });
    }


    clear(): void {
        this.apiResponse = '';
    }

}
