export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'USERS': {
                'TITLE': 'Users',
                // 'BADGE': '25'
            },
            'SPYDER': 'Spyder',
            'WEBSOURCES': {
                'TITLE': 'Web Sources',
                // 'BADGE': '3'
            },
            'CRAWLSTATUS': {
                'TITLE': 'Crawl Status'
            }
        }
    }
};
