import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AuthGuard } from '../../auth/auth.guard';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { LanguageManagementToolComponent } from './language-management-tool/language-management-tool.component';
import { LanguagesManagementToolComponent } from './languages-management-tool/languages-management-tool.component';
import { LanguagesService } from './languages.service';
import { LanguageManagementToolService } from './language-management-tool/language-management-tool.service';

const routes = [
    {
        path: 'languages/languages-management-tool',
        component: LanguagesManagementToolComponent,
        resolve: {
            data: LanguagesService
        },
        canActivate: [
            AuthGuard
        ],
        data: {
            expectedRoles: ['administrator']
        }
    },
    {
        path: 'languages/languages-management-tool/:code',
        component: LanguageManagementToolComponent,
        resolve: {
            data: LanguageManagementToolService
        },
        canActivate: [
            AuthGuard
        ],
        data: {
            expectedRoles: ['administrator']
        }
    },
];

@NgModule({
    declarations: [
        LanguageManagementToolComponent,
        LanguagesManagementToolComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatListModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        ReactiveFormsModule,

        FuseSharedModule
    ],
    providers: [
        AuthGuard,
        LanguagesService,
        LanguageManagementToolService,
    ]
})

export class LanguagesModule {
}
