import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {IGlossary} from '../interfaces/IGlossary';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {GlossaryWordsService} from './glossary-words.service';
import {IPageStateGlossary} from '../../users-and-customers/interfaces/IPageStateGlossary';
import {
    OkCancelDialogComponent,
    OkCancelDialogData,
} from 'app/shared/ok-cancel-dialog/ok-cancel-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import {GlossaryWordService} from '../ideal/ideal.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
    selector: 'app-glossary-words',
    templateUrl: './glossary-words.component.html',
    styleUrls: ['./glossary-words.component.scss']
})
export class GlossaryWordsComponent implements OnInit {
    dataSource = new MatTableDataSource();
    displayedColumns = ['word', 'definition', 'type', 'exampleSentences', 'linkedWords', 'action'];
    glossaryWords: IGlossary[];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('table', { static: false }) table: any;
    data: IPageStateGlossary;
    searchTextChanged = new Subject<string>();
    searchText: string;
    isLoading: boolean;
    resultsLength: number;
    deleteEventFlag = false;


    constructor(
        private _glossaryService: GlossaryWordsService,
        private _idealService: GlossaryWordService,
        private _router: Router,
        private snackBar: MatSnackBar,
        private _route: ActivatedRoute,
        private _fuseProgressBarService: FuseProgressBarService,
        private logger: NGXLogger,
        private _matDialog: MatDialog
    ) {

        this.data = this._route.snapshot.data.data;
        console.log('CustomersComponent data', this.data);
        this.glossaryWords = this.dataSource.data = this.data.glossaryWords;

    }

    ngOnInit(): void {
        // Wait 1 second for user to finish typing in search bar

        this.searchText = this.data.searchText;
        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(text => {
            console.log('searchText', text);
            this.searchText = text;
            this._fuseProgressBarService.show();
            this.reloadWords(true);
        });

        this._route.queryParams.subscribe((params) => {
            const shouldReloadWords = params.r === 'true';
            if (shouldReloadWords) {
                this.reloadWords(true);
            }
        });
    }

    /**
     * Sends search value to searchTextChanged subject
     *
     * @param event
     */
    search(event: any): void {
        this.searchTextChanged.next(event.target.value);
    }

    reloadWords(resetPage: boolean, isDelete = false): void {
        this.isLoading = true;
        this._fuseProgressBarService.show();
        if (resetPage) {
            this.paginator.pageIndex = 0;
        }
        this._glossaryService
            .getWordsList(
                this.paginator.pageSize,
                this.paginator.pageIndex * this.paginator.pageSize,
                this.searchText
            )
            .then(
                (response) => {
                    console.log('Got words: ', response);
                    this.glossaryWords = this.dataSource.data = response.data;
                    this.resultsLength = response.count;
                    this._fuseProgressBarService.hide();
                    this.isLoading = false;
                    if (isDelete) {
                        this.snackBar.open('Das Wort wurde erfolgreich aus dem Glossar entfernt.', 'OK', {
                            duration: 5000,
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                        });
                    }
                },
                (error) => {
                    this._fuseProgressBarService.hide();
                    this.isLoading = false;
                    console.error('Error getting users: ', error);
                }
            );
    }

    openWordDetails(word: IGlossary): void {
        this._glossaryService.pageState = {
            total: this.resultsLength,
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize,
            scrollTop: this.table._elementRef.nativeElement.scrollTop,
            glossaryWords: this.glossaryWords,
            searchText: this.searchText
        };
        this._router.navigateByUrl(`/translation/glossary/${word._id}`);

    }

    getSourceText(index: number): string | undefined {
        return this.glossaryWords[index]?.['source_text_' + (index + 1)];
    }

    getSourceUrl(index: number): string | undefined {
        return this.glossaryWords[index]?.['source_url_' + (index + 1)];
    }

    deleteWord(id: string): void {
        this.deleteEventFlag = true;

        const confirmDialogRef = this._matDialog.open(OkCancelDialogComponent, {
            width: '275px',
            data: {
                header: 'Glossareintrag löschen',
                message: 'Möchten Sie diesen Glossareintrag dauerhaft entfernen?',
            } as OkCancelDialogData,
            disableClose: false,
        });

        confirmDialogRef.afterClosed().subscribe(async (result) => {
            this.logger.debug('The confirmation dialog was closed', result);
            this.deleteEventFlag = false;
            if (result) {
                try {
                    this._fuseProgressBarService.show();
                    const res = await this._idealService.removeWord(id);
                    if (res && res.success) {
                        this.reloadWords(true, true);

                    }
                    this._fuseProgressBarService.hide();
                } catch (e) {
                    this._fuseProgressBarService.hide();
                    this.snackBar.open('Error removing the word. Please try again later.', 'OK', {
                        duration: 5000,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                }
            }
        });
    }
}
