import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface OkCancelDialogData {
    /**
     * Optional header of the dialog
     */
    header?: string;
    /**
     * Message (mandatory)
     */
    message: string;
    /**
     * Optional text if the OK button
     */
    okButton?: string;
    /**
     * Optional text if the cancel button
     */
    cancelButton?: string;
    /**
     * Optional - if true, then the cancel button will not be shown
     */
    hideCancelButton?: boolean;
}

@Component({
    selector: 'ok-cancel-dialog',
    templateUrl: './ok-cancel-dialog.component.html',
    styleUrls: ['./ok-cancel-dialog.component.scss'],
})
export class OkCancelDialogComponent {
    public message: string;
    public header: string;
    public okButton: string;
    public cancelButton: string;
    public hideCancelButton: boolean;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<OkCancelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OkCancelDialogData
    ) {
        console.log('data', data);
        if (data) {
            this.header = data.header || 'Please confirm';
            this.message = data.message;
            this.okButton = data.okButton || 'OK';
            this.cancelButton = data.cancelButton || 'Cancel';
            this.hideCancelButton = data.hideCancelButton;
        }
    }
}
