import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private _authService: AuthService, private logger: NGXLogger) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return new Observable<boolean>(observer => {
            // Load token from storage if we don't have it
            this._authService.checkStorageToken();

            const user = this._authService.user;

            if (this._authService.isLoggedIn() && 
                (route.data.expectedRoles.includes(user.role) || user.corrector && route.data.expectedRoles.includes('corrector'))) {
                observer.next(true);
                observer.complete();
                return;
            }

            this.logger.debug('Not authenticated, redirecting');
            this._authService.redirectByRole();
            observer.next(false);
            observer.complete();
        });
    }
}
