import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
              id: 'languages',
              title: 'Languages',
              type: 'collapsable',
              icon: 'language',
              children: [
                {
                  id: 'translation',
                  title: 'Translation',
                  type: 'collapsable',
                  icon: 'translate',
                  children: [
                    {
                      id: 'suggestions',
                      title: 'Suggestions',
                      type: 'item',
                      icon: 'speaker_notes',
                      url: '/translation/suggestions',
                    },
                    {
                      id: 'frequent-words',
                      title: 'Frequent Words',
                      type: 'item',
                      icon: 'sort',
                      url: '/translation/frequent-words',
                    },
                    {
                      id: 'transl-saved-videos',
                      title: 'Videos',
                      type: 'item',
                      icon: 'video_library',
                      url: '/translation/saved-videos',
                    },
                    {
                      id: 'apply-corrections',
                      title: 'Apply Corrections',
                      type: 'item',
                      icon: 'flag',
                      url: '/translation/apply-corrections',
                    },
                    {
                      id: 'assign-frequent-words',
                      title: 'Assign Frequent Words',
                      type: 'item',
                      icon: 'assignment_ind',
                      url: '/translation/assign-frequent-words',
                    }
                  ]
                },
                {
                  id: 'trtest',
                  title: 'Translation Test Tool',
                  type: 'item',
                  icon: 'done_all',
                  url: '/translations/trtest'
                },
                {
                  id: 'languages-management-tool',
                  title: 'LMT',
                  type: 'item',
                  icon: 'build',
                  url: '/languages/languages-management-tool'
                },
                {
                  id: 'glossary',
                  title: 'Glossareintrag Ideal',
                  type: 'item',
                  icon: 'library_books',
                  url: '/translation/glossary'
                },
              ]
            },
            {
              id: 'spyder',
              title: 'Spyder',
              translate: 'NAV.SPYDER',
              type: 'collapsable',
              icon: 'web',
              children: [
                {
                  id: 'craw-status',
                  title: 'Crawl Status',
                  type: 'item',
                  icon: 'work',
                  url: '/spyder/crawl-status',
                  // badge    : {
                  //     title    : '3',
                  //     translate: 'NAV.WEBSOURCES.BADGE',
                  //     bg       : '#F44336',
                  //     fg       : '#FFFFFF'
                  // }
                },
                {
                  id: 'crawled-videos',
                  title: 'Crawled Videos',
                  type: 'item',
                  icon: 'ondemand_video',
                  url: '/spyder/crawled-videos',
                },
                {
                  id: 'duplicates',
                  title: 'Review Duplicates',
                  type: 'item',
                  icon: 'control_point_duplicate',
                  url: '/spyder/duplicates',
                  showBlueDot: false,
                },
                {
                  id: 'saved-videos',
                  title: 'Saved Videos',
                  type: 'item',
                  icon: 'video_library',
                  url: '/spyder/saved-videos',
                },
                {
                  id: 'review-crawled-videos',
                  title: 'Review New Videos',
                  type: 'item',
                  icon: 'rate_review',
                  url: '/spyder/review-crawled-videos',
                },
                {
                  id: 'video-of-day',
                  title: 'Video of the day',
                  type: 'item',
                  icon: 'calendar_today',
                  url: '/spyder/video-of-day',
                },
                {
                  id: 'single-crawl',
                  title: 'Single Crawl',
                  type: 'item',
                  icon: 'find_in_page',
                  url: '/spyder/single-crawl',
                },
                {
                  id: 'custom-crawl',
                  title: 'Custom Crawl',
                  type: 'item',
                  icon: 'pageview',
                  url: '/spyder/custom-crawl',
                },
                {
                  id: 'translation-status',
                  title: 'Translation Status',
                  type: 'item',
                  icon: 'translate',
                  url: '/spyder/translation-status',
                },
                {
                  id: 'web-sources',
                  title: 'Web Sources',
                  translate: 'NAV.WEBSOURCES.TITLE',
                  type: 'item',
                  icon: 'tv',
                  url: '/spyder/web-sources',
                },
              ]
            },
            {
                id: 'users-and-customers',
                title: 'Users & Customers',
                type: 'collapsable',
                icon: 'people',
                children: [
                    {
                        id: 'users',
                        title: 'Users',
                        type: 'item',
                        icon: 'person_outline',
                        url: '/users-and-customers/users'
                    },
                    {
                        id: 'send-login-link',
                        title: 'Send Login Link',
                        type: 'item',
                        icon: 'mail',
                        url: '/users-and-customers/send-login-link'
                    },
                    {
                        id: 'customers',
                        title: 'Customers',
                        type: 'item',
                        icon: 'people',
                        url: '/users-and-customers/customers'
                    }

                ]
            },
        ]
    }
];
