import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ITranslationCorrection } from '../interfaces/ITranslationCorrection';
import { AuthService } from 'app/auth/auth.service';
import { ITranslation } from '../../spyder/interfaces/ITranslation';
import { IVideo } from '../../spyder/interfaces/IVideo';
import { VideoDetailsService } from '../../spyder/video-details/video-details.service';
import { CONSTANTS } from 'app/constants';
import * as _ from 'lodash';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class SuggestionService implements Resolve<any> {

    public readonly availablePOS = CONSTANTS.AVAILABLE_POS;
    public readonly availablePOSByKey = _.keyBy(this.availablePOS, 'key');
    public readonly availableSubjects = _.sortBy(CONSTANTS.AVAILABLE_DICT_SUBJECTS, ['top', subj => subj.abbrev.toLowerCase()]);
    public readonly availableWordTypes = CONSTANTS.AVAILABLE_WORD_TYPES;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthService,
        private _router: Router,
        private _videoDetailsService: VideoDetailsService,
        private logger: NGXLogger
    ) {
        // Doing nothing.
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Promise<any>}
     */
    resolve(route: ActivatedRouteSnapshot): Promise<any> {
        const state = this._router.getCurrentNavigation().extras.state;
        if (state) {
            return this.getAllSuggestionInformation(state.suggestion);
        } else {
            // Get translation correction document
            return this.getSuggestionById(route.params.id).then(suggestion => {
                return this.getAllSuggestionInformation(suggestion);
            }, error => {
                return new Promise((resolve, reject) => {
                    reject(error.error || error);
                });
            });
        }
    }

    /**
     * Gets a translation suggestion document by ID
     *
     * @param {string} id The ID of the ducument
     * @returns {Promise<any>}
     */
    getSuggestionById(id: string): Promise<ITranslationCorrection> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.serviceApiBaseUrl}/translationcorrections/${id}`)
                .subscribe((response: ITranslationCorrection) => {
                    resolve(response);
                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     * Change a translation suggestion status to either 'approved' or 'rejected'
     *
     * @param {string} status The new status of the suggestion
     * @param {string} id The id of the suggestion
     * @returns {Promise<any>}
     */
    changeSuggestionStatus(status: string, id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.patch(`${environment.serviceApiBaseUrl}/translationcorrections/status/${status}/${id}`, null)
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     * Gets translation document by ID
     *
     * @param {string} id ID of the translation
     * @returns {Promise<any>}
     */
    getTranslationById(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.serviceApiBaseUrl}/translations/${id}`)
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error.error || error);
                });
        });
    }

    /**
     * Helper to get translation and video for a given suggestion
     *
     * @param {ITranslationCorrection} suggestion The suggestion
     * @returns {Promise<any>}
     */
    getAllSuggestionInformation(suggestion: ITranslationCorrection): Promise<{ suggestion: ITranslationCorrection, translation?: ITranslation, video?: IVideo }> {
        return new Promise((resolve, reject) => {
            if (!suggestion.translation_id) {
                resolve({ suggestion: suggestion });
                return;
            }
            // Get translation document
            this.getTranslationById(suggestion.translation_id).then(res => {
                if (res.data) {
                    // Get video details
                    this._videoDetailsService.getSavedVideoDetails(this._authService.userRoleSimple, res.data.video_id).then(video => {
                        resolve({ suggestion: suggestion, translation: res.data, video: video });
                    }, (err) => {
                        this.logger.info('Error loading video:', err);
                        resolve({ suggestion: suggestion, translation: res.data });
                    });
                    // this._httpClient.get(`${environment.serviceApiBaseUrl}/videos/details4${this._authService.userRoleSimple}/${res.data.video_id}`)
                    //     .subscribe(video => {
                    //         resolve({ suggestion: suggestion, translation: res.data, video: video as IVideo });
                    //     }, error => {
                    //         reject(error.error || error);
                    //     });
                } else {
                    resolve({ suggestion: suggestion });
                }
            }, error => {
                reject(error.error || error);
            });
        });
    }

    /**
     * Updates a translation correction document
     *
     * @param {string} id The id of the translation suggestion
     * @param {*} body Any propery that needs to be updated
     * @returns {Promise<any>}
     */
    updateSuggestion(id: string, body: any): Promise<{data: ITranslationCorrection}> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${environment.serviceApiBaseUrl}/translationcorrections/${id}`, body)
                .subscribe(response => {
                    resolve(response as {data: ITranslationCorrection});
                }, error => {
                    reject(error.error || error);
                });
        });
    }
}
