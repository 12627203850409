<div id="users" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
             fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="my-12" fxLayout="row" fxLayoutAlign="start center">
                <h1>
                    Glossareintrag IDEAL
                </h1>
            </div>

            <!-- SEARCH -->
            <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

                <div class="search-wrapper mt-16 mt-sm-0">

                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input (keyup)="search($event)" autocomplete="off" [(ngModel)]="searchText" id="search" matInput
                               #filter placeholder="Suche nach Wörtern">
                    </div>

                </div>

            </div>
            <!-- / SEARCH -->

            <button mat-raised-button class="mat-white-bg mt-sm-0" [routerLink]="'/translation/glossary/new'">
                Wort hinzufügen
            </button>
        </div>

        <div class="table-container content-card mat-white-bg">
            <!--LOADER-->
<!--            <div *ngIf="isLoading" class="loading-shade">-->
<!--                <mat-spinner></mat-spinner>-->
<!--            </div>-->

            <mat-table #table class="customers-table" [dataSource]="dataSource">
                <ng-container matColumnDef="word">
                    <mat-header-cell *matHeaderCellDef>Wort</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.word}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef>Wortart</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.type}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="definition">
                    <mat-header-cell *matHeaderCellDef>Definition</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.definition}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="exampleSentences">
                    <mat-header-cell *matHeaderCellDef>Beispielsätze</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.exampleSentences ? element.exampleSentences.sort(w).join(', ') : '-'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="sources">
                    <mat-header-cell *matHeaderCellDef>Quellen</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.source_text_1 || element.source_text_2 || element.source_text_3">
                            <span *ngIf="element.source_text_1">{{ element.source_text_1 }}</span>
                            <span *ngIf="element.source_text_1 && element.source_text_2">, </span>
                            <span *ngIf="element.source_text_2">{{ element.source_text_2 }}</span>
                            <span *ngIf="element.source_text_2 && element.source_text_3">, </span>
                            <span *ngIf="element.source_text_3">{{ element.source_text_3 }}</span>
                        </ng-container>

                        <ng-container *ngIf="element.source_url_1 || element.source_url_2">
                            <ng-container *ngIf="element.source_text_1 || element.source_text_2 || element.source_text_3">, </ng-container>
                            <a *ngIf="element.source_url_1" href="{{ element.source_url_1 }}" target="_blank">{{ element.source_url_1 }}</a>
                            <span *ngIf="element.source_url_1 && element.source_url_2">, </span>
                            <a *ngIf="element.source_url_2" href="{{ element.source_url_2 }}" target="_blank">{{ element.source_url_2 }}</a>
                        </ng-container>

                        <span *ngIf="!element.source_text_1 && !element.source_text_2 && !element.source_text_3 && !element.source_url_1 && !element.source_url_2">-</span>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="linkedWords">
                    <mat-header-cell *matHeaderCellDef>Zusammenhang</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.linkedWords && element.linkedWords.length > 0; else noLinkedWords">
                            <ng-container *ngFor="let linkedWord of element.linkedWords; let last = last">
                                {{ linkedWord.word }}{{ !last ? ', ' : '' }}
                            </ng-container>
                        </ng-container>
                        <ng-template #noLinkedWords>-</ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-button aria-label="Remove" (click)="deleteWord(element._id)">
                            <mat-icon slot="start">delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row (click)="deleteEventFlag ? $event.stopPropagation() : openWordDetails(row)"
                         *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator (page)="reloadWords(false)" [length]="resultsLength" [pageSizeOptions]="[20, 50, 100, 200]"
                           showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>