import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IGlossary } from '../interfaces/IGlossary';
import {ActivatedRouteSnapshot} from "@angular/router";


@Injectable()
export class GlossaryWordService {

    constructor(private _httpClient: HttpClient) {}

    /**
     * Resolver
     *
     * @returns {Promise<any>}
     */
    resolve(route: ActivatedRouteSnapshot): Promise<any> {
        const id = route.params.id;

        if (id === 'new') {
            return Promise.resolve({
                glossary: null,
            });
        }
        return this.getGlossaryById(id).then(glossary => {
            return Promise.resolve({
                glossary,
            });
        }, error => {
            return Promise.reject(error.error || error);
        });
    }


    /**
     * Adds a new word to the  glossary
     *
     * @param {IGlossary} glossary
     * @returns {Promise<IGlossary>}
     */
    createWord(glossary: IGlossary): Promise<IGlossary> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(`${environment.serviceApiBaseUrl}/glossary`, glossary)
                .subscribe(
                    (response: IGlossary) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error.error || error);
                    }
                );
        });
    }


    /**
     * Remove a  word from the glossary
     *
     * @param {IGlossary} glossary
     * @returns {Promise<IGlossary>}
     */
    removeWord(wordId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .delete(`${environment.serviceApiBaseUrl}/glossary/${wordId}`)
                .subscribe(
                    (response: {success: boolean, data: IGlossary}) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error.error || error);
                    }
                );
        });
    }

    /**
     * Edit an existing word in the glossary.]
     *
     * @param {string} id
     * @param {IGlossary} glossary
     * @returns {Promise<IGlossary | null>}
     */
    updateWord(id: string, glossary: IGlossary): Promise<IGlossary | null> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(`${environment.serviceApiBaseUrl}/glossary/${id}`, glossary)
                .subscribe(
                    (response: IGlossary) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error.error || error);
                    }
                );
        });
    }

    /**
     * Removes a word from the glossary
     *
     * @param {string} id
     * @returns {Promise<IGlossary | null>}
     */
    removeGlossary(id: string): Promise<IGlossary | null> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .delete(`${environment.serviceApiBaseUrl}/glossary/${id}`)
                .subscribe(
                    (response: IGlossary) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error.error || error);
                    }
                );
        });
    }

    /**
     * Fetch a word document
     *
     * @param {string} id
     * @returns {Promise<IGlossary>}
     */
    getGlossaryById(id: string): Promise<IGlossary> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(`${environment.serviceApiBaseUrl}/glossary/${id}`)
                .subscribe(
                    (response: IGlossary) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error.error || error);
                    }
                );
        });
    }

}
