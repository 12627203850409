import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';

export interface PageState {
    languages: {
        _id?: string;
        is_active?: boolean;
        code?: string;
        name?: string;
        name_native?: string;
        is_public?: boolean;
        status?: string;
    };
}

@Injectable()
export class LanguagesService implements Resolve<any> {
    pageState: PageState;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient,
                private _logger: NGXLogger) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Promise<any>}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return this.getLanguages();
    }

    /**
     * Gets all languages
     *
     * @returns {Promise<any>}
     */
    getLanguages(): Promise<any> {
        const params = new HttpParams().set('limit', '60');
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.serviceApiBaseUrl}/languages/all`, { params: params })
                .subscribe(response => {
                    this._logger.debug('Got languages:', response);
                    const languages = Object.values(response);
                    resolve(languages);
                }, error => {
                    this._logger.error('Error getting languages:', error);
                    reject(error.error || error);
                });
        });
    }
}
