<div id="languages" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start"
            fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="my-12" fxLayout="row" fxLayoutAlign="start center">
                <h1 [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Language Management Tool
                </h1>
            </div>

        </div>
        <!-- / HEADER -->

        <div class="table-container content-card mat-white-bg">
            <!--LOADER-->
            <!-- <div *ngIf="isLoading" class="loading-shade">
                <mat-spinner></mat-spinner>
            </div> -->

            <mat-table #table class="languages-table" [dataSource]="dataSource">
                <ng-container matColumnDef="language">
                    <mat-header-cell *matHeaderCellDef>Language</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.name}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.status}}
                    </mat-cell>
                </ng-container>


                <ng-container *matNoDataRow>
                  <div class="my-12 ml-16">
                    <h1 [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                      Empty data
                    </h1>
                  </div>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row [class.inactive]="!row.is_active || (row.status === 'Not available')" (click)="openLanguageDetails(row)" ngClass="table-item"
                *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

            <mat-paginator [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>