import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { ILanguages } from 'app/main/spyder/interfaces/ILanguages';
import { TTranslationCorrectionStatus, ITranslationCorrection, ETranslationCorrectionSource } from 'app/main/translation/interfaces/ITranslationCorrection';
import { ITablePageState } from 'app/main/users-and-customers/interfaces/ITablePageState';
import { ICustomer } from '../../main/users-and-customers/interfaces/ICustomer';
import { IUser } from '../../main/users-and-customers/interfaces/IUser';

export interface PageState extends ITablePageState {
    suggestions: ITranslationCorrection[];
    fromWord?: string;
    createdAtFrom?: string;
    createdAtUntil?: string;
    src?: number;
    customer?: ICustomer;
    customers?: ICustomer[];
    user?: IUser | 'all';
    users?: IUser[];
}

@Injectable()
export class SuggestionsService {

    readonly PREF_KEY_TRANSLATION_CORRECTIONS_FROM = 'pref_translation_corrections_from';
    readonly PREF_KEY_TRANSLATION_CORRECTIONS_TO = 'pref_translation_corrections_to';
    readonly PREF_KEY_TRANSLATION_CORRECTIONS_STATUS = 'pref_translation_corrections_status';

    pageState: PageState;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient, private _authService: AuthService) {
    }

    get from(): ILanguages {
        return localStorage.getItem(this.PREF_KEY_TRANSLATION_CORRECTIONS_FROM) as ILanguages;
    }

    set from(from: ILanguages) {
        localStorage.setItem(this.PREF_KEY_TRANSLATION_CORRECTIONS_FROM, from);
    }

    get to(): ILanguages {
        return localStorage.getItem(this.PREF_KEY_TRANSLATION_CORRECTIONS_TO) as ILanguages;
    }

    set to(to: ILanguages) {
        localStorage.setItem(this.PREF_KEY_TRANSLATION_CORRECTIONS_TO, to);
    }

    get status(): TTranslationCorrectionStatus | 'all' {
        return localStorage.getItem(this.PREF_KEY_TRANSLATION_CORRECTIONS_STATUS) as (TTranslationCorrectionStatus | 'all') || 'pending';
    }

    set status(status: TTranslationCorrectionStatus | 'all') {
        localStorage.setItem(this.PREF_KEY_TRANSLATION_CORRECTIONS_STATUS, status);
    }

    get authSuffix(): string {
        return this._authService.isLoggedIn() ? `_${this._authService.user._id}` : '';
    }


    /**
     * Lists all translation suggestions
     * 
     * @param {ILanguages} from The language the words were tranlslated from
     * @param {ILanguages} to The language the words were translated to
     * @param {number} limit The number of records to get (used for pagination)
     * @param {number} offset The number of records to skip (used for pagination)
     * @param {string} status Filter suggestions by status
     * @param {string} fromWord List of suggestions can be limited to a single word (optional). If $in is used, then the search will be case independet (RegEx).
     * @returns {Promise<any>}
     */
    getTranslationSuggestions(
        from: ILanguages,
        to: ILanguages,
        limit: number,
        offset: number,
        status?: string | string[],
        fromWord?: string | string[] | { $in: string[] },
        created_at_from?: any,
        created_at_until?: any,
        src?: ETranslationCorrectionSource | { $in: ETranslationCorrectionSource[] },
        edu_cust_id?: string,
        educator_id?: string,
    ): Promise<any> {
        const params = new HttpParams()
            .set('limit', limit.toString())
            .set('offset', offset.toString());
        
        if (created_at_from) {
            created_at_from = created_at_from.local().startOf('day');
            // console.log('from:: ', created_at_from.format());
        }
        
        if (created_at_until) {
            created_at_until = created_at_until.local().endOf('day');
            // console.log('until:: ', created_at_until.format());
        }

        const body = {
            fromWord,
            from,
            to,
            rule: 'override',
            rule_file: 'Postwords',
            status: Array.isArray(status) ? { $in: status } : status,
            created_at_from,
            created_at_until,
            src,
            edu_cust_id: edu_cust_id || undefined,
            educator_id: educator_id || undefined
        };
        // console.log('body::', body);

        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.serviceApiBaseUrl}/translationcorrections/list`, body, { params: params })
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error.error || error);
                });
        });
    }



    /**
     * Get a translation correction doc by typing a word into the mat-autocomplete
     * GET translationcorrections/searchbox
     * @returns {Promise<any>}
     */
    async getTranslationSearchbox(fromWordQ: string): Promise<any> {
        const url = `${environment.serviceApiBaseUrl}/translationcorrections/searchbox?fromWordQ=${fromWordQ}`;
        return this._httpClient.get(url).toPromise();
    }





}
